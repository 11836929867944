import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/Jersey M54.ttf";
import { StoreProvider } from "./services/index";
import Web3 from 'web3';
import Web3Provider from 'react-web3-provider'
import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
// import {useWeb3React} from "@web3-react/core"
// const {active, account, library, connector, activate, deactivate} = useWeb3React()
const getLibrary = (provider, connector) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
};

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <StoreProvider>
        <App />
      </StoreProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
