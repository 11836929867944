import React, { useEffect, useState } from "react";

import { useStyles } from "./style";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import metamask from "../../assets/metamask.png";
/////
import { getBalance, getBNBBalance, useStoreApi,fetchAccountAllowance  } from "../../services";
import { useWeb3 } from "../../services";
import Web3Modal from "web3modal";
import ModalLogin from "../ModalLogin/ModalLogin";

////

const DataWallet = [
  {
    id: 1,
    name: "Metamask",
    img: metamask,
  },
];
const ListButton = ({ index, item, onClick, address, balance, type, EWB }) => {
  const classes = useStyles();

  const itemNotSelect = () => {
    return (
      <>
        <button className={classes.containList} key={index} onClick={onClick}>
          <div className={classes.titleBtn}>{item.name}</div>

          <div className={classes.containIcon}>
            <img className={classes.iconMeta} src={item.img} />
          </div>
        </button>
      </>
    );
  };
  const { setAddress, setBalance } = useStoreApi();
  let noteLocalWallet = localStorage.getItem("WALLET");
  const providerOptions = {};
  const web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions, // required
  });
  const DisconnectWallet = async () => {
    await web3Modal.clearCachedProvider();
    localStorage.setItem("ADDRESS", null);
    setAddress(null);
    setBalance(null);
  };

  return (
    <>
      {address ? (
        <>
          {noteLocalWallet === item.name || type === item.name ? (
            <>
              {/* <div style={{ flexDirection: "column", display: "flex" }}>
                <p className={classes.title}>Your address {address}</p>
                <p className={classes.title}>Balance {balance}</p>
              </div> */}
              <ModalLogin
                address={address}
                balanceBNB={balance}
                EWB={EWB}
                onLogout={() => DisconnectWallet()}
              />
              {/* <div
                className={classes.buttonLogout}
                onClick={() => DisconnectWallet()}
              >
                <img src={buttonLogout} className={classes.imgBtn} />
                <div className={classes.logout}>LOGOUT</div>
              </div> */}
              {/* <SimpleButton
                title="LOGOUT"
                color="#fff"
                backGround="#d1b751"
                onClick={DisconnectWallet}
              /> */}
            </>
          ) : null}
        </>
      ) : (
        itemNotSelect()
      )}
    </>
  );
};
const ModalConnect = ({ title, type, setOpen }) => {
  const [typeWallet, setTypeWallet] = useState("");
  const classes = useStyles();
  ////
  const {
    address,
    balance,
    setBalance,
    setAddress,
    setAllowance,
    setEWB,
    EWB,
  } = useStoreApi();
  const web3 = useWeb3();
  const setUserAccount = async (type) => {
    if (window.ethereum) {
      await window.ethereum.enable();
      setTypeWallet(type);
      localStorage.setItem("WALLET", type);
      web3.eth.getAccounts().then(async (accounts) => {
        setAddress(accounts[0]);
        setUserBalanceBNB(accounts[0]);
        setUserBalanceEMB(accounts[0]);
        let allowance = await fetchAccountAllowance(accounts[0]);
        if (allowance < 1000000) {
          setAllowance(false);
        } else {
          setAllowance(true);
        }
      });
    } else {
      setOpen(false);
      alert(`Turn on ${type} wallet`);
    }
  };
  ///Lấy số dư
  const setUserBalanceBNB = async (fromAddress) => {
    let balanceBNB = await getBNBBalance(fromAddress);
    setBalance(balanceBNB);
  };
  const setUserBalanceEMB = async (fromAddress) => {
    let balanceEMB = await getBalance(fromAddress);
    setEWB(balanceEMB);
  };
  
  return (
    <>
      {/**Responsive modal connect */}
      <div className={classes.modal} onClick={() => setOpen(false)}>
        <div className={classes.broad}>
          {DataWallet.map((item, index) => (
            <ListButton
              item={item}
              index={index}
              address={address}
              balance={balance}
              EWB={EWB}
              onClick={() => setUserAccount(item.name)}
              type={typeWallet}
            />
          ))}
          <div onClick={() => setOpen(false)}>
            <ExpandMoreIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalConnect;
