import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  selectGem: {
    width: "100%",
    maxWidth: 150,
    height: "auto",
  },
  img: {
    height: "auto",
    maxWidth: "100px",
  },
  btnGame: {
    background: "linear-gradient(to top,#1e342b,#2a4e4b,#005337)",
    padding: "24px",
    fontSize: "2.5vmin",
    color: "white",
    borderRadius: 50,
    transition: "all 0.2s ease-in-out",

    "&:hover": {
      transition: "all 0.2s ease-in-out",
      textShadow: "2px 2px 8px #fff",
    },
  },
  imgCard: {
    width: "100%",
    height: "100%",
    maxWidth: "200px",
    borderRadius: 10,
  },
  btnFight: {
    padding: "8px 30px 8px 30px",
    fontSize: "2vmin",
    color: "black",
    fontFamily: "Jersey M54",
    transition: "all 0.2s ease-in-out",
    border: "none",
    cursor: "pointer",
    borderRadius: 6,
    "&:hover": {
      transition: "all 0.2s ease-in-out",
      textShadow: "2px 2px 8px #fff",
    },
  },
  FrameGem:{
     backgroundColor:'rgba(0,0,0,0.5)',
     width:"100%",
     maxWidth:600,
     border:"5px solid #000",
     borderRadius:10,
     borderStyle:'inset'
  },
  btnStatusFight: {
    marginTop: "50px",
    padding: "8px 30px 8px 30px",
    fontSize: "2vmin",
    color: "black",
    fontFamily: "Jersey M54",
    transition: "all 0.2s ease-in-out",
    border: "none",
    cursor: "pointer",
    borderRadius: 6,
    "&:hover": {
      transition: "all 0.2s ease-in-out",
      textShadow: "2px 2px 8px #fff",
    },
  },

  btnScore: {
    marginTop: "5px",
    padding: "8px 30px 8px 30px",
    fontSize: "2vmin",
    color: "black",
    fontFamily: "Jersey M54",
    transition: "all 0.2s ease-in-out",
    border: "none",
    cursor: "pointer",
    borderRadius: 6,
    "&:hover": {
      transition: "all 0.2s ease-in-out",
      textShadow: "2px 2px 8px #fff",
    },
  },

  btnWin: {
    background:
      "linear-gradient(to top,#6a1111,#711414,#801a1a,#a72929,#cd3838,#fa4a4a)",
    padding: "8px 64px 8px 64px",
    fontSize: "1.5vmin",
    color: "white",
    fontFamily: "Jersey M54",
    transition: "all 0.2s ease-in-out",
    border: "none",
    borderRadius: 6,
    "&:hover": {
      transition: "all 0.2s ease-in-out",
      textShadow: "2px 2px 8px #fff",
    },
  },
  containFrameGem:{
    marginTop:150
  },
  containBtn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 16,
    marginLeft: 20,
    marginRight: 20,
  },
  containCard: {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%,-50%)',
    display: "flex",
    
  },
  columnBtn:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    height:'330px'
  },
  containBtn2:{
      display:"flex",
      flexDirection: 'column',
      justifyContent:"center",
      marginTop:16,
      marginLeft:20,
      marginRight:20
  },
  VSview:{
    height:'50px',  
  },
  vsImg:{
    width:'100%',
    height:'100%',
    objectFit:'contain'
  },
  grow: {
    flexGrow: 1,
  },
  containGem: {
    marginRight: 64,
  },
  emptyImg: {
    border: "3px dashed white",
    width: 200,
    height: 325,
    borderRadius: 10,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    color: "white",
    "&:hover": {
      transition: "all 0.2s ease-in-out",
      textShadow: "2px 2px 8px #fff",
      color: "white",
      cursor: "pointer",
    },
  },
  containCard2: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  frameBtn: {
    position: "relative",
    textAlign: "center",
    color: "white",
    margin:20,
    "&:hover": {
        transition: "all 0.2s ease-in-out",
        textShadow: "2px 2px 8px #fff",
        color: "white",
      },
  },
  frameImg: {
    width: "100%",
    maxWidth: 200,
    height: "auto",
  },
  titleImg: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  titleGem:{
    marginBottom:16,
    margtinTop:16,
    color:"#fff"
  },
  containTxGem:{
    display:"flex",
    justifyContent:"center",

  },
  gem:{
    width:"100%",
    height:"auto",
    maxWidth:100,
    position:'absolute',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  notiCT:{
    position: "absolute",
    top: "50%",
    left: "50%",
    textAlign:'center',
    transform: "translate(-50%, -50%)",
    backgroundColor:'rgba(0,0,0,0.5)',
    width:"100%",
    paddingTop:20,
    paddingBottom:20
  }
}));
