import React from 'react'
import "../../styles/globalStyle.css"
export const FlyEffect = () => {
    return <>
          <ul className="fireflies">
        <li className="fly"></li>
        <li className="fly"></li>
        <li className="fly"></li>
        <li className="fly"></li>
        <li className="fly"></li>
      </ul>
    </>
}