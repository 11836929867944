import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import "../../styles/rainbowEffect.css";
import FadeIn from "react-fade-in";
import "./style.css";
import { GreyButton } from "..";
import upgrade from "../../assets/upgrade.png";
import ReactAudioPlayer from "react-audio-player";
import {
  DataImage,
  DataClass,
  DataRare,
  useStoreApi,
  getStakeStatus,
  getAllGEM,
  myEffect,
  DataGemFrame,
  getAllItem,
} from "../../services";

import { getAllCARD } from "../../services";
import Img from "react-cool-img";
import LazyLoad from "react-lazyload";
import LoadingPage from "../LoadingPage/LoadingPage";
import clsx from "clsx";

const CardComponent = React.memo(({ item, index, onClick, type }) => {
  const classes = useStyles();
  const [imgCard, setImgCard] = useState("");
  const [imgClass, setImgClass] = useState("");
  const [imgCounter, setImgCounter] = useState("");
  const [imgRare, setImgRare] = useState("");
  const [effect, setEffect] = useState("");
  const [status, setStatus] = useState(false);
  const checkCard = () => {
    DataImage.map((data) => {
      if (data.id === Number(item.idImg)) {
        setImgCard(data.img);
      }
    });
  };
  const checkStatus = async () => {
    if (type === "STAKING") {
      let status = await getStakeStatus(item.id);
      setStatus(status);
    }
  };
  const checkClass = () => {
    DataClass.map((data) => {
      if (data.id === Number(item.class)) {
        setImgClass(data.img);
      }
      if (data.name === item.counter) {
        setImgCounter(data.img);
      }
    });
  };
  const checkRare = () => {
    DataRare.map((data) => {
      if (data.id === Number(item.rare)) {
        setImgRare(data.img);
      }
    });
  };
  const randomEffect = () => {
    let effectcard = myEffect[Math.floor(Math.random() * myEffect.length)];
    return effectcard.name;
  };
  const checkEffect = () => {
    if (Number(item.rare) === 0 || Number(item.rare) === 1) {
      setEffect("card0");
    }
    if (Number(item.rare) === 2 || Number(item.rare) === 3) {
      let effectCard = randomEffect();
      setEffect(effectCard);
    }
    if (Number(item.rare) === 4) {
      setEffect("rainbow");
    }
  };
  const [display, setDisplay] = useState(false);
  const checkCardExistCountDown = () => {
    if (type === "PVE" || type === "PVP") {
      if (!conditionDisplay(item)) {
        setDisplay(true);
      }
    } else {
      setDisplay(true);
    }
  };
  const checkStatusStaking = async (id) => {
    let statusFlag = await getStakeStatus(id);
    return statusFlag;
  };
  //When a card is in countdown or reached the maximum turn per day or Staking
  // This function will exclude from the Popup List
  const conditionDisplay = (item) => {
    let display;
    let turn = [3, 4, 5, 7, 9];
    // let stakingStatus =  getStakeStatus(item.id);
    //console.log(status);
    turn.map((data, index) => {
      if (
        (Number(item.turn) < data &&
          Number(item.rare) === index &&
          Number(item.lastplayedTime) > Date.now() / 1000 - 3600) ||
        (Number(item.rare) === index && Number(item.turn) === data)
      ) {
        let date = new Date(Number(item.lastplayedTime) * 1000).setUTCHours(
          0,
          0,
          0,
          0
        );
        let today = new Date().setUTCHours(0, 0, 0, 0);
        if (Number(today) > Number(date)) {
          display = false;
        } else {
          display = true;
        }
        if (checkStatusStaking(item.id)) {
          display = true;
        }
      }
    });
    return display;
  };

  useEffect(() => {
    checkCard();
    checkClass();
    checkRare();
    checkEffect();
    checkStatus();
    checkCardExistCountDown();
  }, []);
  return (
    <>
      {status || !display ? null : (
        <FadeIn>
          <div style={{ padding: 32 }} key={index}>
            <div
              className={effect}
              style={{
                position: "relative",
                color: "white",
                height: 328,
              }}
            >
              <Img
                className="gemPurple"
                src={imgClass}
                style={{
                  position: "absolute",
                  width: "36px",
                  height: "36px",
                  marginLeft: "82px",
                  marginTop: "187px",
                }}
              />
              <Img src={imgCard} className={classes.imgCard} />
              <Img
                style={{
                  position: "absolute",
                  width: "24px",
                  bottom: 287,
                  left: 11,
                }}
                src={imgRare}
                className="numberCard3-popup"
              />
              <div className="img-content-popup">
                <div className="img-content-popup-left">
                  <p>POWER : {item.power}</p>
                  <p>DEFENCE : {item.defence}</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div>COUNTER : </div>
                    <Img
                      className="gemPurple-couter-popup-left"
                      src={imgCounter}
                      style={{
                        width: "14px",
                        height: "14px",
                      }}
                    />
                  </div>
                  <p>BONUS REWARD : {item.reward}%</p>
                </div>
                <div className="img-content-popup-right">
                  <p>LEVEL : {item.level}</p>
                  <p>EXP : {item.exp}</p>
                  <p>TURN : {item.turn}</p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 16,
              }}
            >
              <div className={classes.containBtn}>
                <GreyButton title="CHOOSE" onClick={onClick} />
              </div>
            </div>
          </div>
        </FadeIn>
      )}
    </>
  );
});
export const PopupCard = ({ setOpen, selectCard, type, open }) => {
  const { address } = useStoreApi();
  const classes = useStyles();
  const [YourCard, setYourCard] = useState([]);
  const [loading, setLoading] = useState(false);
  const getDataCard = async () => {
    if (address) {
      setLoading(true);
      let snapshot = await getAllCARD(address).then((res) => res);
      let resultData = snapshot.filter((item) => Number(item.bornTime) !== 0);
      setYourCard(resultData);
      setLoading(false);
    }
  };
  useEffect(() => {
    getDataCard();
  }, [address, open]);
  return (
    <>
      <div className={classes.containModal} onClick={() => setOpen(false)}>
        <div className={classes.containCard}>
          {YourCard.length === 0 && !loading ? (
            <div className={classes.content}>You don't have any card</div>
          ) : (
            YourCard.map((item, index) => (
              <LazyLoad>
                <CardComponent
                  item={item}
                  index={index}
                  onClick={() => selectCard(item)}
                  type={type}
                />
              </LazyLoad>
            ))
          )}
        </div>
        {loading && <LoadingPage />}
      </div>
    </>
  );
};

export const PopupNotify = ({ onClick, title }) => {
  const [seconds, setSeconds] = React.useState(20);
  const [minutes, setMinutes] = React.useState(0);
  const [hours, setHours] = React.useState(18);
  const [days, setDays] = React.useState(80);
  const [timeDone, setDone] = React.useState(false);
  //SECONDS

  //seconds 60 -> 0
  useEffect(() => {
    const secondsCounter =
      seconds > 0 && setInterval(() => setSeconds(seconds - 1), 1000);
    return () => clearInterval(secondsCounter);
  }, [seconds]);

  //seconds == 60 (when seconds == 0)
  useEffect(() => {
    return () => setSeconds(60);
  }, [seconds == 0 && !timeDone]);

  //MINUTES

  //Minutes 60 -> 0  (when seconds == 0)
  useEffect(() => {
    return () => setMinutes(minutes - 1);
  }, [seconds == 0]);

  //Minutes == 60 (when minutes == 0)
  useEffect(() => {
    return () => setMinutes(60);
  }, [minutes == 0 && !timeDone]);

  //HOURS

  //hours 24 => 0 (when minutes == 0)
  useEffect(() => {
    return () => setHours(hours - 1);
  }, [minutes == 0]);

  //hours == 23 (when hours == 0)
  useEffect(() => {
    return () => setHours(23);
  }, [hours == 0 && !timeDone]);

  //DAYS
  //days 5 => 0 (when hours == 0)
  useEffect(() => {
    return () => setDays(days - 1);
  }, [hours == 0 && !timeDone]);

  //DONE

  useEffect(() => {
    return () => setDone(true);
  }, [days == 0 && hours == 0 && minutes == 0 && seconds == 0]);
  const classes = useStyles();
  return (
    <>
      <div className={classes.containModal} onClick={() => onClick(false)}>
        <div style={{ minHeight: "100vh" }}>
          <div class="middle font-face-gm">
            <h1>{title}</h1>
            <hr />
            <p style={{ fontSize: "30px" }}>
              {days}d : {hours}h : {minutes}m : {seconds}s
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
const GemComponent = React.memo(({ item, index, onClick, type }) => {
  const classes = useStyles();
  const [img, setImg] = useState("");
  const queryImg = () => {
    if (type === "UPGRADE") {
      DataGemFrame.map((data) => {
        if (item.idImg === data.id) {
          setImg(data.img);
        }
      });
    } else {
      switch (Number(item.gem)) {
        case 0:
          setImg(DataGemFrame[0].img);
          break;
        case 1:
          setImg(DataGemFrame[2].img);
          break;
        case 2:
          setImg(DataGemFrame[1].img);
          break;
        case 3:
          setImg(DataGemFrame[3].img);
          break;
        case 4:
          setImg(DataGemFrame[4].img);
          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    queryImg();
  }, []);
  return (
    <div className="gemList font-face-gm" key={index}>
      <img className="gemList-img" src={img} />

      {type === "UPGRADE" ? null : (
        <div className="gemList-content">
          <h3 className="gemList-content-title">
            Bonus {item.idImg === "onyx" ? "Reward" : "Exp"}{" "}
          </h3>
          <h3 className="gemList-content-subtitle">
            Between {item.lowest}% And {item.highest}%
          </h3>
        </div>
      )}
      <div className={clsx(classes.containBtn3, "btn-Item")}>
        <GreyButton title="Choose" onClick={onClick} />
      </div>
    </div>
  );
});
export const PopupGem = React.memo(
  ({ setOpen, selectGem, open, type, item }) => {
    const { address } = useStoreApi();
    const classes = useStyles();
    const [YourGem, setYourGem] = useState([]);
    const [loading, setLoading] = useState(false);
    const getDataGem = async () => {
      setLoading(true);
      if (address) {
        let snapshot;
        if (type === "UPGRADE") {
          let result = await getAllItem(address).then((res) => res);
          if (item === "SPINEL") {
            snapshot = result.filter((data) => data.idImg === "SPINEL");
          }
          if (item === "GARNET") {
            snapshot = result.filter((data) => data.idImg === "GARNET");
          }
        } else {
          snapshot = await getAllGEM(address).then((res) => res);
        }

        let resultData = snapshot.filter((item) => Number(item.bornTime) !== 0);
        setYourGem(resultData);
        setLoading(false);
      }
    };
    useEffect(() => {
      getDataGem();
    }, [address, open]);
    return (
      <div className={classes.containModal} onClick={() => setOpen(false)}>
        <div className={classes.containCard}>
          {YourGem.length === 0 && !loading ? (
            <div className={clsx(classes.content, "font-face-gm")}>
              You don't have any {type === "UPGRADE" ? "item" : "gem"}
            </div>
          ) : (
            YourGem.map((item, index) => (
              <LazyLoad>
                <GemComponent
                  item={item}
                  index={index}
                  onClick={() => selectGem(item)}
                  type={type}
                />
              </LazyLoad>
            ))
          )}
        </div>
        {loading && <LoadingPage />}
      </div>
    );
  }
);

export const PopupUpgrade = ({
  cardOld,
  cardNew,
  setClose,
  reset,
  loading,
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.containModal, "center")}
      onClick={() => {
        setClose(false);
        reset();
      }}
    >
      <ReactAudioPlayer
        src="http://nhacchuongvui.com/wp-content/uploads/Hieu-ung-am-thanh-xuat-hien-www_nhacchuongvui_com.mp3"
        autoPlay
        //controls
      />
      <div style={{ marginTop: 64 }}>
        <GreyButton title="RESULT" />
      </div>
      <FadeIn>
        <div className={classes.containCard}>
          {/**OLD */}
          <div style={{ padding: 120 }}>
            <div
              className={cardOld.effect}
              style={{
                position: "relative",
                color: "white",
                height: 410,
              }}
            >
              <Img
                className="gemPurple"
                src={cardOld.classImg}
                style={{
                  position: "absolute",
                  width: "42px",
                  height: "42px",
                  marginLeft: "104px",
                  marginTop: "235px",
                }}
              />
              <Img src={cardOld.imgCard} className={classes.imgCard2} />
              <Img
                style={{
                  position: "absolute",
                  width: "30px",
                  bottom: 360,
                  left: 13,
                }}
                src={cardOld.rare}
                className="numberCard3-popup"
              />
              <div className="img-content-popup2">
                <div className="img-content-popup-left2">
                  <p>POWER : {cardOld.power}</p>
                  <p>DEFENCE : {cardOld.defence}</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div>COUNTER : </div>
                    <Img
                      className="gemPurple-couter-popup-left2"
                      src={cardOld.imgCounter}
                      style={{
                        width: "14px",
                        height: "14px",
                      }}
                    />
                  </div>
                  <p>BONUS REWARD : {cardOld.reward}%</p>
                </div>
                <div className="img-content-popup-right2">
                  <p>LEVEL : {cardOld.level}</p>
                  <p>EXP : {cardOld.exp}</p>
                  <p>TURN : {cardOld.turn}</p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 16,
              }}
            ></div>
          </div>
          {/**LINE */}
          <div>
            {/* <div style={{width:2,height:"100%",backgroundColor:'#fff',}}></div> */}
            <img
              src={upgrade}
              style={{
                //position:'absolute',
                width: "100%",
                height: "auto",
                maxWidth: 200,
              }}
            />
          </div>

          {/**NEW */}

          <div style={{ padding: 120 }}>
            <div
              className={cardNew.effect}
              style={{
                position: "relative",
                color: "white",
                height: 410,
              }}
            >
              <Img
                className="gemPurple"
                src={cardNew.class}
                style={{
                  position: "absolute",
                  width: "42px",
                  height: "42px",
                  marginLeft: "104px",
                  marginTop: "235px",
                }}
              />
              <Img src={cardNew.img} className={classes.imgCard2} />
              <Img
                style={{
                  position: "absolute",
                  width: "30px",
                  bottom: 360,
                  left: 13,
                }}
                src={cardNew.rare}
                className="numberCard3-popup"
              />
              <div className="img-content-popup2">
                <div className="img-content-popup-left2">
                  <p>POWER : {cardNew.power}</p>
                  <p>DEFENCE : {cardNew.defence}</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div>COUNTER : </div>
                    <Img
                      className="gemPurple-couter-popup-left2"
                      src={cardNew.imgCounter}
                      style={{
                        width: "14px",
                        height: "14px",
                      }}
                    />
                  </div>
                  <p>BONUS REWARD : {cardNew.reward}%</p>
                </div>
                <div className="img-content-popup-right2">
                  <p>LEVEL : {cardNew.level}</p>
                  <p>EXP : {cardNew.exp}</p>
                  <p>TURN : {cardNew.turn}</p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 16,
              }}
            ></div>
          </div>
        </div>
      </FadeIn>
      {loading ? <LoadingPage /> : null}
    </div>
  );
};
