import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import "./style.css";

import boardResult from "../../assets/03-02.svg";
import ModalApprove from "../../components/ModalApprove/ModalApprove";
import { GreyButton } from "../../components";
import ModalDynamic from "../../components/ModalDynamic/ModalDynamic";
import ModalConnect from "../../components/ModalConnect/ModalConnect";
import Img from "react-cool-img";
import { ClaimReward, MyReward, useStoreApi } from "../../services";
import moment from "moment";
import LoadingPage from "../../components/LoadingPage/LoadingPage";

export const YourRewardPage10 = ({ setOpen }) => {
  const { address, allowance } = useStoreApi();
  const [reward, setReward] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const getMyReward = async () => {
    try {
      setLoading(true);
      if (address) {
        let snapshot = await MyReward(address).then(async (res) => res);
        setReward(snapshot);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const [noti, setNoti] = useState(false);
  const [status, setStatus] = useState("");
  const onClaim = async () => {
    try {
      setLoading(true);
      let snapshot = await ClaimReward(address).then(async (res) => res);
      if (snapshot) {
        setNoti(true);
        setStatus("Your reward has been successfully claimed");
      } else {
        setStatus("Your reward has been unsuccessfully claimed");
      }
      setLoading(false);
    } catch (error) {
      setNoti(true);
      setLoading(false);
      setStatus("Your reward has been unsuccessfully claimed");
    }
  };
  const [claim, setClaim] = useState(false);
  const onDisplayClaim = () => {
    let currentTime = new Date().getTime();
    if (reward.timeforNextClaim !== undefined) {
      if (currentTime >= Number(reward.timeforNextClaim) * 1000) {
        setClaim(true);
      }
    }
  };
  useEffect(() => {
    getMyReward();
  }, [address, noti]);
  useEffect(() => {
    onDisplayClaim();
  }, [address, reward]);
  return (
    <div>
      <div className="containModal">
        <div className="modal">
          <Img className="awardModal" src={boardResult} />
          <div className="modal-award-content">
            <div className="modal-award-content-top">
              <div className="theme">REWARD</div>
              <div className="theme-rw">
                Current Reward: <br />
              </div>
              <div>
                {reward.amount !== undefined
                  ? (Number(reward.amount.substring(-18)) / 1e18)
                      .toFixed(1)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                  : 0}{" "}EMP
              </div>
              <div className="theme-rw">
                Next Claimed Time: <br />
              </div>
              <div>
                {reward.timeforNextClaim !== undefined
                  ? Number(reward.timeforNextClaim) === 0
                    ? "00:00:00"
                    : moment(Number(reward.timeforNextClaim) * 1000).format(
                        "DD/MM/YYYY"
                      )
                  : 0}{" "}
                -{" "}
                {reward.timeforNextClaim !== undefined
                  ? Number(reward.timeforNextClaim) === 0
                    ? "00:00:00"
                    : new Date(
                        Number(reward.timeforNextClaim) * 1000
                      ).toLocaleTimeString()
                  : 0}{" "}
              </div>
              <div className="theme-rw">
                Total Earned Reward: <br />
              </div>
              <div>
                {reward.total !== undefined
                  ? (Number(reward.total.substring(-18)) / 1e18)
                      .toFixed(1)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                  : 0}{" "}EMP
              </div>

              <div className="theme-rw">
                Total Claimed: <br />
              </div>
              <div>
                {reward.claimed !== undefined
                  ? (Number(reward.claimed.substring(-18)) / 1e18)
                      .toFixed(1)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                  : 0}{" "}EMP
              </div>
            </div>
          </div>
          <div className="close-btn">
            <div>
              <GreyButton title="CLOSE" onClick={() => setOpen(false)} />
            </div>
            <div>
              {claim && (
                <GreyButton
                  title="CLAIM"
                  onClick={() => {
                    if (!address) {
                      setOpenModal(true);
                    } else {
                      onClaim();
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <ModalDynamic/> */}
      {openModal ? <ModalConnect type="another" open={openModal} /> : null}
      {noti ? <ModalDynamic type="REWARD" title={status} onClick={setNoti} /> : null}
      {loading && <LoadingPage />}
      {!allowance ? <ModalApprove /> : null}
    </div>
  );
};
