import Web3Modal from "web3modal";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { abi } from ".";
// const env = {
//   BSCSCAN: "https://testnet.bscscan.com/address/",
//   ROUTERGEM: "0xbd3d558B1Ce69fdb00D0456713dC2E424CE4E275",
//   NFTGEM: "0x0aA644E40da925F25cF491c1F3b6d3d28E66a4CF",
//   TOKEN: "0x3159bFB195C6C390f107091456b553d44e97209B",
//   ROUTERCARD: "0x952f22eA00EcfB9Dc0bb3Ade5c23D864129Ac972",
//   NFTCARD: "0xf24a255Ca6e011955028F50443e00399FE743F9B",
//   NFTITEM: "0x9795FE6258e40d9919945BF49Ce1a0F0B0a3fCa6",
//   REWARD : "0xb9c0da774B1b2c84aA724880f160202a62726e80"
// };

export const initstore = {
  accountData: null,
  balance: null,
  allowance: null,
};
const readProvider = async () => {
  await window.web3.currentProvider.enable();
  let web3Instance = new Web3(window.web3.currentProvider);
  return web3Instance;
};
const getProviderOptions = () => {
  const providerOption = {
    walletconnect: {
      package: new WalletConnectProvider({
        infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
      }),
      option: {
        rpc: {
          1: "https://bsc-dataseed1.defibit.io",
          56: "https://bsc-dataseed1.defibit.io",
        },
      },
    },
  };
  return providerOption;
};
let web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions: getProviderOptions(),
});
let provider;
let web3Instance;
let contractExt;
let addressWallet;
const token = process.env.REACT_APP_TOKEN_KEY;
const nftGem = process.env.REACT_APP_NFTGEM_KEY;
const nftCard = process.env.REACT_APP_NFTCARD_KEY;
const routerGEM = process.env.REACT_APP_ROUTERGEM_KEY;
const routerCARD = process.env.REACT_APP_ROUTERCARD_KEY;
const nftItem = process.env.REACT_APP_NFTITEM_KEY;
const reward = process.env.REACT_APP_REWARD_KEY;

const getProvider = async () => {
  provider = await web3Modal.connect();
  return provider;
};
export const contractInit = () => {
  // web3Modal = new Web3Modal({
  //   cacheProvider: true,
  //   providerOptions: getProviderOptions(),
  // });
  // await web3Modal.connect().then((res)=> {
  //   console.log(res)
  // })

  onConnect(web3Modal);
  //console.log("AUTO CONNECT");
};

const onConnect = async () => {
  try {
    let Provider = await getProvider();
    //console.log(Provider);
  } catch (err) {
    //console.log("Could not get a wallet connection", err);
  }
  // Provider.on("accountsChanged", (accounts) => {
  //   console.log('accounts:', accounts)
  //   //logout()
  // });
  // // Subscribe to chainId change
  // Provider.on("accountsChanged", (chainId) => {
  //   //logout()
  // });

  // // Subscribe to networkId change
  // Provider.on("accountsChanged", (networkId) => {
  //   //logout()
  // });
  //initstore.accountData = await fetchAccountData(provider);
  //getInfo(provider);
  //   const gem = await getAllGEM();
  //   console.log(gem);
};

const fetchAccountData = async (provider) => {
  web3Instance = new Web3(provider);
  contractExt = new web3Instance.eth.Contract(abi, "");
  const account = await web3Instance.eth.getAccounts();
  addressWallet = account[0];
  return addressWallet;
};
const getInfo = async (provider) => {
  web3Instance = new Web3(provider);
  const account = await web3Instance.eth.getAccounts();
  initstore.allowance = await fetchAccountAllowance(web3Instance);
  //initstore.balance = await getBalance(initstore.accountData);
};

export const getBalance = async (account) => {
   let web3Instance = await readProvider()
   const tokenInst = new web3Instance.eth.Contract(abi, token);
   const bal = await tokenInst.methods.balanceOf(account).call();
   return bal / 1e18;
 }
export const fetchAccountAllowance = async (addressWallet) => {
  let web3Instance = await readProvider()
  contractExt = new web3Instance.eth.Contract(abi,token);
  const allowance = await contractExt.methods
    .allowance(addressWallet, routerCARD)
    .call();
  return allowance;
};

export const onLogout = async () => {
  if (provider.disconnect) {
    await provider.disconnect();
    await web3Modal.clearCachedProvider();
  }
  provider = null;
  addressWallet = null;
  initstore.accountData = null;
  initstore.balance = 0;
};
const reloadBalance = async (addressWallet) => {
  //initstore.balance = await getBalance(addressWallet);
};
export const getBNBBalance = async (account) => {
  let web3Instance = await readProvider()
  const a = await web3Instance.eth.getBalance(account);
  return parseFloat(web3Instance.utils.fromWei(a, "ether"));
};
/////////////////////////////////////////////////////////////
//Buy GEM
// return status

export const BuyGEM = async (addressWallet) => {
  // await window.web3.currentProvider.enable()
  // let web3Instance = new Web3(window.web3.currentProvider)
  let web3Instance = await readProvider();
  let sortAbi = [
    {
      inputs: [],
      name: "generateGem",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "payable",
      type: "function",
    },
  ];
  let price = 0.5;
  let routerExt = new web3Instance.eth.Contract(sortAbi, routerGEM);
  let tx = {
    from: addressWallet,
    to: routerGEM,
    value: parseFloat(web3Instance.utils.toWei(price.toString())),
    data: routerExt.methods.generateGem().encodeABI(),
  };
  return web3Instance.eth.sendTransaction(tx).on('transactionHash', function(hash){
    //console.log(hash)
  })
};
export const GetGEM = async (id) => {
  // await window.web3.currentProvider.enable()
  // let web3Instance = new Web3(window.web3.currentProvider)
  let web3Instance = await readProvider();
  let sortAbi = [
    {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         }
      ],
      "name":"getGemInfo",
      "outputs":[
         {
            "components":[
               {
                  "internalType":"uint256",
                  "name":"id",
                  "type":"uint256"
               },
               {
                  "internalType":"enum GEMEMP.GEM",
                  "name":"gem",
                  "type":"uint8"
               },
               {
                  "internalType":"string",
                  "name":"idImg",
                  "type":"string"
               },
               {
                  "internalType":"uint256",
                  "name":"lowest",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"highest",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"bornTime",
                  "type":"uint256"
               }
            ],
            "internalType":"struct GEMEMP.GemInfo",
            "name":"",
            "type":"tuple"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   }
  ];
  let contractExt = new web3Instance.eth.Contract(sortAbi, nftGem);
  const data = await contractExt.methods.getGemInfo(id).call();
  return data;
};

export const getAllGEM = async (addressWallet) => {
  let web3Instance = await readProvider()
  let sortAbi = [
    {
      "inputs":[
         {
            "internalType":"address",
            "name":"_owner",
            "type":"address"
         }
      ],
      "name":"getGEM",
      "outputs":[
         {
            "components":[
               {
                  "internalType":"uint256",
                  "name":"id",
                  "type":"uint256"
               },
               {
                  "internalType":"enum GEMEMP.GEM",
                  "name":"gem",
                  "type":"uint8"
               },
               {
                  "internalType":"string",
                  "name":"idImg",
                  "type":"string"
               },
               {
                  "internalType":"uint256",
                  "name":"lowest",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"highest",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"bornTime",
                  "type":"uint256"
               }
            ],
            "internalType":"struct GEMEMP.GemInfo[]",
            "name":"",
            "type":"tuple[]"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   }
  ];

  let contractExt = new web3Instance.eth.Contract(sortAbi, nftGem);
  const data = await contractExt.methods.getGEM(addressWallet).call();
  let items = [];
  data.forEach( function(item) {
    if (item.bornTime != 0) {
      items.push(item);
    }
  });
  //console.log(items);
  return data;
};
export const approve = async (account) => {
  let web3Instance = await readProvider()
  contractExt = new web3Instance.eth.Contract(abi, token);

  let amount = 4000000000;
  let tx = {
    from: account,
    to: contractExt._address,
    data: contractExt.methods
      .approve(routerCARD, web3Instance.utils.toWei(amount.toString()))
      .encodeABI(),
  };
  return web3Instance.eth.sendTransaction(tx);
};
export const GetCARD = async (id) => {
  // await window.web3.currentProvider.enable()
  // let web3Instance = new Web3(window.web3.currentProvider)
  let web3Instance = await readProvider()
  let sortAbi = [
    {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_tokenId",
            "type":"uint256"
         }
      ],
      "name":"getCardInfo",
      "outputs":[
         {
            "components":[
               {
                  "internalType":"uint256",
                  "name":"id",
                  "type":"uint256"
               },
               {
                  "internalType":"enum EMPCARD.CLASS",
                  "name":"class",
                  "type":"uint8"
               },
               {
                  "internalType":"uint256",
                  "name":"idImg",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"rare",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"level",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"exp",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"power",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"defence",
                  "type":"uint256"
               },
               {
                  "internalType":"string",
                  "name":"counter",
                  "type":"string"
               },
               {
                  "internalType":"uint256",
                  "name":"reward",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"turn",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"lastplayedTime",
                  "type":"uint256"
               },
               {
                  "internalType":"bool",
                  "name":"market",
                  "type":"bool"
               },
               {
                  "internalType":"uint256",
                  "name":"bornTime",
                  "type":"uint256"
               }
            ],
            "internalType":"struct EMPCARD.CardInfo",
            "name":"",
            "type":"tuple"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   }
  ];
  let contractExt = new web3Instance.eth.Contract(sortAbi, nftCard);
  const data = await contractExt.methods.getCardInfo(id).call();
  return data;
}

//Get All Card
export const getAllCARD = async (addressWallet) => {
  let web3Instance = await readProvider()
  let sortAbi = [
    {
      "inputs":[
         {
            "internalType":"address",
            "name":"_owner",
            "type":"address"
         }
      ],
      "name":"getCard",
      "outputs":[
         {
            "components":[
               {
                  "internalType":"uint256",
                  "name":"id",
                  "type":"uint256"
               },
               {
                  "internalType":"enum EMPCARD.CLASS",
                  "name":"class",
                  "type":"uint8"
               },
               {
                  "internalType":"uint256",
                  "name":"idImg",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"rare",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"level",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"exp",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"power",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"defence",
                  "type":"uint256"
               },
               {
                  "internalType":"string",
                  "name":"counter",
                  "type":"string"
               },
               {
                  "internalType":"uint256",
                  "name":"reward",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"turn",
                  "type":"uint256"
               },
               {
                  "internalType":"uint256",
                  "name":"lastplayedTime",
                  "type":"uint256"
               },
               {
                  "internalType":"bool",
                  "name":"market",
                  "type":"bool"
               },
               {
                  "internalType":"uint256",
                  "name":"bornTime",
                  "type":"uint256"
               }
            ],
            "internalType":"struct EMPCARD.CardInfo[]",
            "name":"",
            "type":"tuple[]"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   }
  ];

  let contractExt = new web3Instance.eth.Contract(sortAbi, nftCard);
  const data = await contractExt.methods.getCard(addressWallet).call();
  //console.log(data)
  return data;
};

//Buy Cards

export const BuyCard = async (id, addressWallet) => {
  let web3Instance = await readProvider()
  let sortAbi = [
    {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_id",
            "type":"uint256"
         }
      ],
      "name":"generateCard",
      "outputs":[
         
      ],
      "stateMutability":"nonpayable",
      "type":"function"
   }
  ];
  let routerExt = new web3Instance.eth.Contract(sortAbi, routerCARD);
  let tx = {
    from: addressWallet,
    to: routerCARD,
    data: routerExt.methods.generateCard(id).encodeABI(),
  };
  return web3Instance.eth.sendTransaction(tx)
};

//PVE Fight
//return json {
// result: win/lose
// reward: string

//Boss
// id_img: 01
// id: int
// Rare: 2
// Level: 1
// Exp: 5
// attribute: fire
// power :  5
// defence:5

//}
export const Fight = async (id, id_button, idGem, addressWallet) => {
  let web3Instance = await readProvider()
  let sortAbi = [
   {
		"inputs": [
			{
				"internalType": "uint256",
				"name": "id",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "bet",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "idGem",
				"type": "uint256"
			}
		],
		"name": "battle",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
  ];
  let routerExt = new web3Instance.eth.Contract(sortAbi, routerCARD);
  let tx = {
    from: addressWallet,
    to: routerCARD,
    data: routerExt.methods.battle(id, id_button, idGem).encodeABI(),
  };
  return web3Instance.eth.sendTransaction(tx).on('transactionHash', function(hash){
    //console.log(hash)
  })
};

//Check Card Owner
export const CardHolder = async (id) => {
  let web3Instance = await readProvider()
  let sortAbi = [
    {
      "inputs":[
         {
            "internalType":"address",
            "name":"_holder",
            "type":"address"
         },
         {
            "internalType":"uint256",
            "name":"_cardId",
            "type":"uint256"
         }
      ],
      "name":"cardHolder",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   }
  ];

  let contractExt = new web3Instance.eth.Contract(sortAbi, nftCard);
  const data = await contractExt.methods.cardHolder(id).call();
  return data;
};

//Get Stake status
export const getStakeStatus = async (id) => {
  let web3Instance = await readProvider()
  let sortAbi = [
    {
      "inputs":[
         {
            "internalType":"uint256",
            "name":"_id",
            "type":"uint256"
         }
      ],
      "name":"getStake",
      "outputs":[
         {
            "internalType":"bool",
            "name":"",
            "type":"bool"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   }
  ];

  let contractExt = new web3Instance.eth.Contract(sortAbi, routerCARD);
  const data = await contractExt.methods.getStake(id).call();
  return data;
};

//Get Stake Time
export const getStakeTime = async (id) => {
   let web3Instance = await readProvider()
   let sortAbi = [
      {
         "inputs":[
            {
               "internalType":"uint256",
               "name":"_id",
               "type":"uint256"
            }
         ],
         "name":"getStakeTime",
         "outputs":[
            {
               "internalType":"uint256",
               "name":"",
               "type":"uint256"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      }
   ];
 
   let contractExt = new web3Instance.eth.Contract(sortAbi, routerCARD);
   const data = await contractExt.methods.getStakeTime(id).call();
   return data;
 };

 //Get Stake Pool
export const getStakePool = async (id) => {
   let web3Instance = await readProvider()
   let sortAbi = [
      {
         "inputs":[
            {
               "internalType":"uint256",
               "name":"_id",
               "type":"uint256"
            }
         ],
         "name":"getStakePool",
         "outputs":[
            {
               "internalType":"uint256",
               "name":"",
               "type":"uint256"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      }
   ];
 
   let contractExt = new web3Instance.eth.Contract(sortAbi, routerCARD);
   const data = await contractExt.methods.getStakePool(id).call();
   return data;
 };

//Stake
export const Stake = async (id, id_button, addressWallet) => {
   let web3Instance = await readProvider()
   let sortAbi = [
     {
       "inputs":[
          {
             "internalType":"uint256",
             "name":"id",
             "type":"uint256"
          },
          {
             "internalType":"uint256",
             "name":"id_stake",
             "type":"uint256"
          }
       ],
       "name":"stakeCard",
       "outputs":[
          
       ],
       "stateMutability":"nonpayable",
       "type":"function"
    }
   ];
   let routerExt = new web3Instance.eth.Contract(sortAbi, routerCARD);
   let tx = {
     from: addressWallet,
     to: routerCARD,
     data: routerExt.methods.stakeCard(id, id_button).encodeABI(),
   };
 
   return web3Instance.eth.sendTransaction(tx).on('transactionHash', function(hash){
     //console.log(hash)
   })
 };

//UnStake
// return : true/false
export const UnStake = async (id, idGem, addressWallet) => {
  let web3Instance = await readProvider()
  let sortAbi = [
   {
		"inputs": [
			{
				"internalType": "uint256",
				"name": "id",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "idGem",
				"type": "uint256"
			}
		],
		"name": "unStakeCard",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
  ];
  let routerExt = new web3Instance.eth.Contract(sortAbi, routerCARD);
  let tx = {
    from: addressWallet,
    to: routerCARD,
    data: routerExt.methods.unStakeCard(id, idGem).encodeABI(),
  };
  return web3Instance.eth.sendTransaction(tx).on('transactionHash', function(hash){
    //console.log(hash)
  })
};

//Get Stake Time
export const getRandomCard = async (id) => {
   let web3Instance = await readProvider()
   let sortAbi = [
      {
         "inputs":[
            {
               "internalType":"uint256",
               "name":"_id",
               "type":"uint256"
            }
         ],
         "name":"getRandomCard",
         "outputs":[
            {
               "internalType":"uint256",
               "name":"",
               "type":"uint256"
            }
         ],
         "stateMutability":"view",
         "type":"function"
      }
   ];
 
   let contractExt = new web3Instance.eth.Contract(sortAbi, routerCARD);
   const data = await contractExt.methods.getRandomCard(id).call();
   return data;
 };

 //Get Item 
 export const GetItem = async (id) => {
   // await window.web3.currentProvider.enable()
   // let web3Instance = new Web3(window.web3.currentProvider)
   let web3Instance = await readProvider();
   let sortAbi = [
      {
         "inputs": [
           {
             "internalType": "uint256",
             "name": "_tokenId",
             "type": "uint256"
           }
         ],
         "name": "getItemInfo",
         "outputs": [
           {
             "components": [
               {
                 "internalType": "uint256",
                 "name": "id",
                 "type": "uint256"
               },
               {
                 "internalType": "enum ITEMEMP.ITEM",
                 "name": "item",
                 "type": "uint8"
               },
               {
                 "internalType": "string",
                 "name": "idImg",
                 "type": "string"
               },
               {
                 "internalType": "uint256",
                 "name": "bornTime",
                 "type": "uint256"
               }
             ],
             "internalType": "struct ITEMEMP.ItemInfo",
             "name": "",
             "type": "tuple"
           }
         ],
         "stateMutability": "view",
         "type": "function"
       }
   ];
   let contractExt = new web3Instance.eth.Contract(sortAbi, nftItem);
   const data = await contractExt.methods.getItemInfo(id).call();
   return data;
 };


//Get All Item
export const getAllItem = async (addressWallet) => {
   let web3Instance = await readProvider()
   let sortAbi = [
      {
         "inputs": [
           {
             "internalType": "address",
             "name": "_owner",
             "type": "address"
           }
         ],
         "name": "getITEM",
         "outputs": [
           {
             "components": [
               {
                 "internalType": "uint256",
                 "name": "id",
                 "type": "uint256"
               },
               {
                 "internalType": "enum ITEMEMP.ITEM",
                 "name": "item",
                 "type": "uint8"
               },
               {
                 "internalType": "string",
                 "name": "idImg",
                 "type": "string"
               },
               {
                 "internalType": "uint256",
                 "name": "bornTime",
                 "type": "uint256"
               }
             ],
             "internalType": "struct ITEMEMP.ItemInfo[]",
             "name": "",
             "type": "tuple[]"
           }
         ],
         "stateMutability": "view",
         "type": "function"
       }
   ];
 
   let contractExt = new web3Instance.eth.Contract(sortAbi, nftItem);
   const data = await contractExt.methods.getITEM(addressWallet).call();
   //console.log(data)
   return data;
 }; 

//My Reward
export const MyReward = async (addressWallet) => {
   let web3Instance = await readProvider()
   let sortAbi = [
      {
         "inputs": [
            {
               "internalType": "address",
               "name": "_player",
               "type": "address"
            }
         ],
         "name": "getPlayer",
         "outputs": [
            {
               "components": [
                  {
                     "internalType": "uint256",
                     "name": "total",
                     "type": "uint256"
                  },
                  {
                     "internalType": "uint256",
                     "name": "amount",
                     "type": "uint256"
                  },
                  {
                     "internalType": "uint256",
                     "name": "claimed",
                     "type": "uint256"
                  },
                  {
                     "internalType": "uint256",
                     "name": "timeforNextClaim",
                     "type": "uint256"
                  }
               ],
               "internalType": "struct RewardStorage.Claimer",
               "name": "",
               "type": "tuple"
            }
         ],
         "stateMutability": "view",
         "type": "function"
      }
   ];
 
   let contractExt = new web3Instance.eth.Contract(sortAbi, reward);
   const data = await contractExt.methods.getPlayer(addressWallet).call();
   //console.log(data)
   return data;
};

//Claim Reward
export const ClaimReward = async (addressWallet) => {
   //id : thẻ nhân vật mình chọn
   //id_gem : là id gem
   // id_button : card win/ boss win
   let web3Instance = await readProvider()
   let sortAbi = [
      {
         "inputs": [],
         "name": "claimReward",
         "outputs": [
            {
               "internalType": "bool",
               "name": "",
               "type": "bool"
            }
         ],
         "stateMutability": "nonpayable",
         "type": "function"
      }
   ];
   let routerExt = new web3Instance.eth.Contract(sortAbi, routerCARD);
   let tx = {
     from: addressWallet,
     to: routerCARD,
     data: routerExt.methods.claimReward().encodeABI(),
   };
   return web3Instance.eth.sendTransaction(tx).on('transactionHash', function(hash){
     //console.log(hash)
   })
 };

 //Upgrade
 export const upgrade = async (id, idItem1, idItem2, addressWallet) => {
   //id : thẻ nhân vật mình chọn
   //id_gem : là id gem
   // id_button : card win/ boss win
   let web3Instance = await readProvider()
   let sortAbi = [
      {
         "inputs": [
            {
               "internalType": "uint256",
               "name": "id",
               "type": "uint256"
            },
            {
               "internalType": "uint256",
               "name": "idItem1",
               "type": "uint256"
            },
            {
               "internalType": "uint256",
               "name": "idItem2",
               "type": "uint256"
            }
         ],
         "name": "upgradeCard",
         "outputs": [],
         "stateMutability": "nonpayable",
         "type": "function"
      }
   ];
   let routerExt = new web3Instance.eth.Contract(sortAbi, routerCARD);
   let tx = {
     from: addressWallet,
     to: routerCARD,
     data: routerExt.methods.upgradeCard(id, idItem1, idItem2).encodeABI(),
   };
   return web3Instance.eth.sendTransaction(tx).on('transactionHash', function(hash){
     //console.log(hash)
   })
 };
export const Combat = async (id, idEnemy, idGem, addressWallet) => {
  let web3Instance = await readProvider();
  let sortAbi = [
   {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "id",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_id",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "idGem",
          "type": "uint256"
        }
      ],
      "name": "combat",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ];
  let routerExt = new web3Instance.eth.Contract(sortAbi, routerCARD);
  let tx = {
    from: addressWallet,
    to: routerCARD,
    data: routerExt.methods.combat(id, idEnemy, idGem).encodeABI(),
  };

  return web3Instance.eth
    .sendTransaction(tx)
    .on("transactionHash", function (hash) {
    });
};

//2 muc nay xai cho Upgrade
// khi mo man hinh upgrade, check AllowanceItem != true ?  approveItem : continue
export const approveItem = async (account) => {
   let web3Instance = await readProvider();
   let sortAbi = [
      {
         "inputs": [
            {
               "internalType": "address",
               "name": "operator",
               "type": "address"
            },
            {
               "internalType": "bool",
               "name": "approved",
               "type": "bool"
            }
         ],
         "name": "setApprovalForAll",
         "outputs": [],
         "stateMutability": "nonpayable",
         "type": "function"
      }
    ];
   contractExt = new web3Instance.eth.Contract(sortAbi, nftItem);
 
   let tx = {
     from: account,
     to: contractExt._address,
     data: contractExt.methods
       .setApprovalForAll(nftItem, true)
       .encodeABI(),
   };
   return web3Instance.eth.sendTransaction(tx);
};

export const AllowanceItem = async (addressWallet) => {
   let web3Instance = await readProvider();
   let sortAbi = [
      {
         "inputs": [
            {
               "internalType": "address",
               "name": "owner",
               "type": "address"
            },
            {
               "internalType": "address",
               "name": "operator",
               "type": "address"
            }
         ],
         "name": "isApprovedForAll",
         "outputs": [
            {
               "internalType": "bool",
               "name": "",
               "type": "bool"
            }
         ],
         "stateMutability": "view",
         "type": "function"
      }
    ];
   contractExt = new web3Instance.eth.Contract(sortAbi,nftItem);
   const allowance = await contractExt.methods
     .isApprovedForAll(addressWallet, nftItem)
     .call();
   return allowance;
 };