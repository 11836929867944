import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  body: {
    minHeight: "100vh",
  },
  orbitRadius: {
    borderRadius: 190,
  },
  gemReceive: {
    width: "100%",
    height: "auto",
    maxWidth: "200px",
  },
  containGem: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  gem: { position: "relative" },
  containDes: {
    position: "absolute",
    bottom:24,
    left:10,
    padding:24,
    textAlign:"center"
  },
  containSpin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  des: {
    color: "#fff",
    fontSize: 12,
  },
}));
