import React from "react";
import "./modalDynamic.css";
import boardResult from "../../assets/login board + buttons/LGBT-02.png";
import { GreyButton } from "../../components";
import Img from "react-cool-img";

export default function ModalDynamic({ title, onClick, reset, type }) {
  return (
    <div
      className="containModal"
      onClick={() => {
        onClick(false);
        if (type === "REWARD") {
          reset();
        }
      }}
    >
      <div className="board-dynamic">
        <Img src={boardResult} alt="" className="board-dynamic-img" />
        <div className="board-dynamic-content">
          <p className="board-dynamic-content-title">{title}</p>
        </div>
        <div className="modal-award-content-bottom2">
          <GreyButton title="CLOSE" onClick={() =>{
         onClick(false);
         if (type === "REWARD") {
           reset();
         }
          }} />
        </div>
      </div>
    </div>
  );
}
