import Web3 from "web3";
import { useEffect, useState } from "react";
import { Injected } from "./connector";
import { useWeb3React } from "@web3-react/core";
export const useWeb3 = () => {
    const [web3, setWeb3 ] = useState(null)
    useEffect(()=>{
        var instance;
        if(window.ethereum){
            try{
                instance = new Web3(window.ethereum)
            }catch(err){
                console.error(err)
            }
        }else if(window.web3){
            instance = new Web3(window.web3)
        }else{
            //fallback on local network
            const provider = new Web3.providers.HttpProvider("http://127.0.0.1:7545")
            instance = new Web3(provider)
        }
        setWeb3(instance)
    },[])
    return web3;
}

