import React from 'react'
import "./modalLogin.css"
import boardLogin from "../../assets/03-02.png"
import bnbImg from "../../assets/bnb.png"
import logo from "../../assets/logo.png"
import buyBtn from "../../assets/login board + buttons/LGBT-03.png"
import LogoutBtn from "../../assets/login board + buttons/LGBT-04.png"
import Img from "react-cool-img";

export default function ModalLogin({address,balanceBNB,EWB,onLogout,onBuy}) {
    return (
        <div className="containModal">
            <div className="board-login">
                <Img src={boardLogin} alt="" className="board-login-img" />
                <div className="board-login-content">
                    <p className="board-login-iduser">{address}</p>
                    <div className="board-login-infor">
                        <div className="board-login-inforBNB">
                            <Img src={bnbImg} alt="" className="bnbImg" />
                            <div className="board-login-inforBNB-content">
                                <p className="board-loginBNB-title">Balance</p>
                                <p className="board-loginBNB-subtitle">{balanceBNB.toFixed(8).replace(/\d(?=(\d{3})+\.)/g, '$&,')} BNB</p>
                            </div>
                        </div>

                        <div className="board-login-inforLogo">
                            <Img src={logo} alt="" className="logoImg" />
                            <div className="board-login-inforLogo-content" style={{ paddingLeft: 10 }}>
                                <p className="board-loginLogo-title">Balance</p>
                                <p className="board-loginLogo-subtitle">{EWB.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')} EMP</p>
                            </div>
                        </div>
                    </div>
                    <div className="board-login-button">
                        <button className="board-login-buttonBuy" onClick={onBuy}>
                            <Img className="board-login-buttonBuy-img" src={buyBtn} />
                        </button>
                        <button className="board-login-buttonLogout" onClick={onLogout}>
                            <Img className="board-login-buttonLogout-img" src={LogoutBtn} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
