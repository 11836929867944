import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Footer, ModalError } from "../../components";
import { useStyles } from "./style";
import "./style-buypage03.css";
import "../../styles/rainbowEffect.css";
import "../../styles/cardEffect.css";
import card from "../../assets/chest-04.svg";
import card2 from "../../assets/chestopen.svg";
import chest1 from "../../assets/chest-03.svg";
import chest2 from "../../assets/chest-02.svg";
import FadeIn from "react-fade-in";
import {
  BuyCard,
  DataImage,
  GetCARD,
  DataClass,
  DataRare,
  myEffect,
} from "../../services";
import { useStoreApi } from "../../services";
import ModalConnect from "../../components/ModalConnect/ModalConnect";
import { SimpleButton, FlyEffect } from "../../components";
import ModalApprove from "../../components/ModalApprove/ModalApprove";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import Img from "react-cool-img";
import Fireword from "../../components/FireworkEfect/FireworkEffect";
import ReactAudioPlayer from "react-audio-player";
const FireCircle = () => {
  return (
    <div className="root">
      <div className="body">
        <div className="circle">
          <svg>
            <filter id="wavy">
              <feTurbulence
                x="0"
                y="0"
                baseFrequency="0.009"
                numOctaves="5"
                speed="2"
              >
                <animate
                  attributeName="baseFrequency"
                  dur="60s"
                  values="0.02; 0.005; 0.02"
                  repeatCount="indefinite"
                />
              </feTurbulence>
              <feDisplacementMap in="SourceGraphic" scale="30" />
            </filter>
          </svg>
        </div>
      </div>
    </div>
  );
};
const DataChest = [
  {
    id: 0,
    card1: card,
    card2: card2,
    rate1: 40,
    rate2: 30,
    rate3: 20,
    rate4: 10,
    rate5: "",
    emp: 18000,
  },
  {
    id: 1,
    card1: chest1,
    card2: chest2,
    rate1: "",
    rate2: 40,
    rate3: 30,
    rate4: 15,
    rate5: 15,
    emp: 27000,
  },
];
export const BuyPage03 = () => {
  const classes = useStyles();
  const [cardRandom, setCardRandom] = useState("");
  const [display, setDisplay] = useState(false);
  const [clickBuy, setClickBuy] = useState(false);
  const { address, allowance, EWB } = useStoreApi();
  const [openModal, setOpenModal] = useState(false);
  const [cardOnClick, setCardOnClick] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [cardPrint, setCardPrint] = useState("");
  const randomEffect = () => {
    let effectcard = myEffect[Math.floor(Math.random() * myEffect.length)];
    return effectcard.name;
  };
  const [error, setError] = useState(false);
  const [noti,setNoti] = useState("")
  const randomCard = async (item) => {
    let printItem;
    if (address) {
      if (allowance) {
        if (EWB < item.emp) {
          setNoti("EMP Balance is not enough")
          setError(true);
        } else {
          //
          setClickBuy(true);
          try {
            let id = await BuyCard(item.id, address).then(async (res) =>
              parseInt(res.logs[4].data.substr(-64), 16)
            );
            printItem = await turnRandomCard(id);
            setTimeout(() => {
              setCardRandom(item.card2);
              setTimeout(() => {
                setDisplay(true);
                //console.log(printItem);
                setCardPrint(printItem);
              }, 2000);
            }, 3000);
          } catch (err) {
            setNoti("Buy is failed")
            setError(true)
            setClickBuy(false);
          }
          ///
        }
      } else {

        setIsLoading(true);
      }
    } else {
      setOpenModal(true);
    }
  };

  const turnRandomCard = async (id) => {
    let classData = [];
    let rareData = [];
    let img = [];
    let effect;
    let counter = [];
    let newCard;
    try {
      await GetCARD(id).then((res) => {
        //console.log(res);
        img = DataImage.filter((item) => item.id === Number(res.idImg));
        //console.log(img)
        classData = DataClass.filter((item) => item.id === Number(res.class));

        rareData = DataRare.filter((item) => item.id === Number(res.rare));
        //counter
        counter = DataClass.filter((item) => item.name === res.counter);
        ///effect rare
        switch (rareData[0].id) {
          case 0:
            effect = "card0";
            break;
          case 1:
            effect = "card0";
            break;
          case 2:
            effect = randomEffect();
            break;
          case 3:
            effect = randomEffect();
            break;
          case 4:
            effect = "rainbow";
            break;
          default:
            break;
        }
        newCard = {
          class: classData[0].img,
          img: img[0].img,
          power: res.power,
          defence: res.defence,
          counter: counter.length !== 0 ? counter[0].img : "",
          reward: res.reward,
          level: res.level,
          exp: res.exp,
          turn: res.turn,
          rare: rareData[0].img,
          effect,
        };
      });
    } catch (err) {
      setClickBuy(false);
    }
    return newCard;
  };

  return (
    <div className="cover font-face-gm">
      {/**Cover */}
      <FlyEffect />
      <div className="body-buyPage">
        <FadeIn delay={5}>
          {!clickBuy ? null : <FireCircle />}
          <div className={classes.containCard}>
            {!clickBuy ? (
              <div className={clsx("containCard2", classes.containCard2)}>
                {DataChest.map((item, index) => (
                  <div className={clsx("frame", classes.frame)}>
                    <div className={classes.containCard1} key={index}>
                      <Img
                        src={item.card1}
                        className={clsx("chest", classes.imgCardDefault)}
                      />
                      <div className="chestContent">
                        <h3 className="chestContent-title">
                          {item.emp
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          $SS
                        </h3>
                        <div className="chestContent-subtitle">
                          <div className="chestContent-left">
                            {item.rate1 === "" ? null : <p>RARE 1 : </p>}
                            {item.rate2 === "" ? null : <p>RARE 2 : </p>}
                            {item.rate3 === "" ? null : <p>RARE 3 : </p>}
                            {item.rate4 === "" ? null : <p>RARE 4 : </p>}
                            {item.rate5 === "" ? null : <p>RARE 5 : </p>}
                          </div>
                          <div className="chestContent-right">
                            {item.rate1 == "" ? null : <p> {item.rate1}%</p>}
                            {item.rate2 == "" ? null : <p> {item.rate2}%</p>}
                            {item.rate3 == "" ? null : <p> {item.rate3}%</p>}
                            {item.rate4 == "" ? null : <p> {item.rate4}%</p>}
                            {item.rate5 == "" ? null : <p> {item.rate5}%</p>}
                          </div>
                        </div>
                      </div>
                      <SimpleButton
                        title={allowance ? "BUY" : "APPROVE"}
                        color={index === DataChest.length - 1 ? "red" : "#000"}
                        backGround={
                          index === DataChest.length - 1 ? "#000" : "red"
                        }
                        onClick={() => {
                          randomCard(item);
                          setCardOnClick(item.card1);
                        }}
                        className="btnBuy"
                      />
                      {/* <button className="btnBuy">BUY</button> */}
                    </div>
                  </div>
                ))}
              </div>
            ) : cardRandom == "" ? (
              <div>
                <Img src={cardOnClick} className="image-bounce" />
              </div>
            ) : !display ? (
              <FadeIn>
                <Img
                  src={cardRandom}
                  className={clsx("chest", classes.imgCardDefault2)}
                />
              </FadeIn>
            ) : (
              cardPrint !== "" && (
                <FadeIn>
                  <div className={classes.fireword}>
                    <Fireword />
                  </div>
                  <ReactAudioPlayer
                    src="http://nhacchuongvui.com/wp-content/uploads/Tieng-Phao-No-www_nhacchuongvui_com.mp3?_=1"
                    autoPlay
                    //controls
                  />
                  <div className={classes.relative}>
                    <Img
                      src={cardPrint.img}
                      className={clsx(classes.imgCardBuyPage)}
                    />
                    <Img
                      src={cardPrint.rare}
                      className={clsx("numberCard3-buyPage", classes.cardPrint)}
                    />
                    <Img
                      className="gemPurple-buyPage"
                      src={cardPrint.class}
                      className={classes.class}
                    />
                    <div className="img-content-buyPage">
                      <div className="img-content-buyPage-left">
                        <p>POWER : {cardPrint.power}</p>
                        <p>DEFENCE : {cardPrint.defence}</p>
                        <div className={classes.layout1}>
                          <div>COUNTER : </div>
                          {cardPrint.counter === "" ? (
                            ""
                          ) : (
                            <img
                              className={clsx(
                                "gemPurple-couter-buyPage",
                                classes.counter
                              )}
                              src={cardPrint.counter}
                            />
                          )}
                        </div>
                        <p>BONUS REWARD : {cardPrint.reward}%</p>
                      </div>
                      <div className="img-content-buyPage-right">
                        <p>LEVEL : {cardPrint.level}</p>
                        <p>EXP : {cardPrint.exp}</p>
                        <p>TURN : {cardPrint.turn}</p>
                      </div>
                    </div>
                  </div>

                  <div
                    className={classes.containBtn}
                    onClick={() => {
                      setCardRandom("");
                      setCardPrint("");
                      setClickBuy(false);
                    }}
                  >
                    <SimpleButton title="OK" color="red" backGround="#000" />
                  </div>
                </FadeIn>
              )
            )}
          </div>

          {/* </div>
          </div> */}
        </FadeIn>
      </div>
      {/** */}
      {/**Footer */}

      <div>
        <Footer />
      </div>
      {error && (
        <ModalError errors={noti} onClick={setError} />
      )}
      {loading ? <LoadingPage /> : null}
      {!allowance ? <ModalApprove /> : null}
      {openModal && <ModalConnect setOpen={setOpenModal} />}
    </div>
  );
};
