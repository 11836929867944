import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import error from "../../assets/ic_error.png";
import FadeIn from "react-fade-in";
import errorImg from "../../assets/error.png";
import "./modalError.css";

export const ModalError = ({ title, onClick, errors }) => {
  const classes = useStyles();
  return (
    <>
      {/**Responsive modal connect */}
      <div className={classes.containModal} onClick={() => onClick(false)}>
        <FadeIn>
          {/* <div className={classes.modal} >   */}
          <div className="broad font-face-gm">
            <div onClick={() => onClick(false)} className="clear">
              <img src={error} className="iconClear" />
            </div>
            <img src={errorImg} className="errorImg" />
            {errors !== undefined ? (
             <div>{errors}</div> 
            ) : (
              <div>
                <div className="title"> {title} not connect </div>
                <p className="subTitle">
                  {" "}
                  An error has occured while creating an error report.
                </p>
              </div>
            )}
          </div>
          {/* </div> */}
        </FadeIn>
      </div>
    </>
  );
};
