import React from 'react'
import "./loadingPage.css"
import logo from "../../assets/logo.png"

export default function LoadingPage() {
    return (
        <div className='body-loadingPage'>
            <div className="modal-loadingPage">
                <div class="loader">
                    
                </div>
                <img src={logo} className="logo-loadingPage"/>
            </div>
        </div>
    )
}

