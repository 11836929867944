import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import {
  AppBar,
  Avatar,
  Divider,
  IconButton,
  ListItem,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Menu from "../../assets/1-01.png";
import logo from "../../assets/logo.png"
import { useStyles } from "./style";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ModalConnect from "../ModalConnect/ModalConnect";
import {
  useStoreApi,
  getBalance,
  getBNBBalance,
  fetchAccountAllowance,
} from "../../services";
import { AuthButton, ModalError, SimpleButton } from "..";
import { YourRewardPage10 } from "../../page";
import { useWeb3 } from "../../services";
import Web3Modal from "web3modal";

const DataLink = [
  // {
  //   name: "GEM",
  //   link: "/",
  //   img: "https://www.location-moto-car-phuket.com/wp-content/uploads/2014/07/ShopIcon.png",
  // },
  //openpage
  {
    name: "CARD",
    link: "/",
    img: "https://i.ibb.co/HTMfMRF/klipartz-com.png",
  },
  {
    name: "PVE",
    link: "pvepage",
    img: "https://i.ibb.co/RQVRkQR/Pngtree-vector-coins-icon-3788228.png",
  },
  {
    name: "PVP",
    link: "pvppage",
    img: "https://i.ibb.co/rvXWGrq/Pngtree-icon-card-game-vector-5538752.png",
  },
  {
    name: "Upgrade",
    link: "upgrade",
    img: "https://cdn-icons-png.flaticon.com/128/3522/3522092.png",
  },
  {
    name: "STAKING",
    link: "stakingpage",
    img: "https://cdn-icons-png.flaticon.com/128/3522/3522092.png",
  },
  {
    name: "ADVENTURE",
    link: "adventure",
    img: "https://cdn-icons-png.flaticon.com/128/3522/3522092.png",
  },
  {
    name: "MARKET",
    link: "market",
    img: "https://cdn-icons-png.flaticon.com/128/3522/3522092.png",
  },
  {
    name: "YOUR REWARD",
    link: "yourrewardpage",
    img: "https://cdn-icons-png.flaticon.com/128/3522/3522092.png",
  },
  {
    name: "MY VAULT",
    link: "cardpage",
    img: "https://cdn-icons-png.flaticon.com/128/3522/3522092.png",
  },
];
export const Navbar = () => {
  const classes = useStyles();
  const [navbar, setNavbar] = useState(false);
  //navbar scroll changBackground function
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });
  const [state, setState] = useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="font-face-gm">
        <Link to="/" style={{ display: "flex", justifyContent: "center" }}>
          <Avatar
            variant="square"
            src={logo}
            style={{ height: 50, width: 50, padding: 16 }}
          />
        </Link>
        {DataLink.map((tab, index) => (
          <Link
            key={index.toString()}
            to={tab.link}
            style={{
              textDecoration: "none",
            }}
          >
            <ListItem
              button
              key={tab.name}
              onClick={toggleDrawer(anchor, false)}
            >
              <Typography className={classes.menuTitleMobile}>
                {tab.name}
              </Typography>
            </ListItem>
          </Link>
        ))}
        <Divider />
        <ListItem>
          <AuthButton
            title={
              address
                ? `${address.substring(1, 4)}...${address.substring(
                    address.length - 3,
                    address.length
                  )}`
                : "LOGIN"
            }
            effect={address ? "animated-button2" : "animated-button1"}
            onClick={() => handleOpenModal()}
          />
        </ListItem>
      </List>
    </div>
  );
  //responsive desktop and mobile screen
  const [showNav, setShowNav] = useState(false);
  const showDropdown = () => {
    if (window.innerWidth <= 960) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  };
  window.addEventListener("resize", showDropdown);
  useEffect(() => {
    showDropdown();
  }, []);
  ///Open modal when click
  //check wallet available
  const [openModal, setOpenModal] = useState(false);
  const {
    address,
    balance,
    setBalance,
    setAddress,
    setAllowance,
    setEWB,
    EWB,
  } = useStoreApi();
  const web3 = useWeb3();
  const setUserAccount = async () => {
    if (window.ethereum) {
      await window.ethereum.enable();
      web3.eth.getAccounts().then(async (accounts) => {
        setAddress(accounts[0]);
        setUserBalanceBNB(accounts[0]);
        setUserBalanceEMB(accounts[0]);
        let allowance = await fetchAccountAllowance(accounts[0]);
        if (allowance < 1000000) {
          setAllowance(false);
        } else {
          setAllowance(true);
        }
      });
    }
  };
  const setUserBalanceBNB = async (fromAddress) => {
    let balanceBNB = await getBNBBalance(fromAddress);
    setBalance(balanceBNB);
  };
  const setUserBalanceEMB = async (fromAddress) => {
    let balanceEMB = await getBalance(fromAddress);
    setEWB(balanceEMB);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const [reward, setReward] = useState(false);
  ////log out
  return (
    <>
      <div className="font-face-gm">
        <AppBar
          position="fixed"
          className={
            navbar ? classes.backgroundNavbar2 : classes.backgroundNavbar
          }
        >
          {showNav ? (
            <Toolbar className={classes.sectionDesktop}>
              <Link to="/" style={{ marginRight: 32 }}>
                <div className="css3-bounce">
                  <Avatar
                    variant="square"
                    src={logo}
                    className={classes.tab}
                  />
                </div>
              </Link>
              {/**shop page */}
              {/* <Link to="/" className={classes.tabs}>
                <div className={classes.nav}>GEM</div>
              </Link> */}
              <Link to="/" className={classes.tabs}>
                <div className={classes.nav}>SOUL</div>
              </Link>

              <Link to="/" className={classes.tabs}>
                <div className={classes.nav}>SWORD</div>
              </Link>

              <Link to="pvepage" className={classes.tabs}>
                <div className={classes.nav}>BOSS</div>
              </Link>

              <Link to="pvppage" className={classes.tabs}>
                <div className={classes.nav}>BATTLE</div>
              </Link>

              <Link to="stakingpage" className={classes.tabs}>
                <div className={classes.nav}>STAKING</div>
              </Link>
              <Link to="upgrade" className={classes.tabs}>
                <div className={classes.nav}>BLACKSMITH</div>
              </Link>              
              
              <div className={classes.grow} />
              <div
                to="yourrewardpage"
                className={classes.tabs}
                onClick={() => setReward(true)}
              >
                <div className={classes.nav}>MY REWARD</div>
              </div>
              <Link to="cardpage" className={classes.tabs}>
                <div className={classes.nav}>STORAGE</div>
              </Link>

              {/**Login */}

              <div className={classes.containAuth}>
                <AuthButton
                  title={
                    address
                      ? `${address.substring(0, 4)}...${address.substring(
                          address.length - 3,
                          address.length
                        )}`
                      : "CONNECT"
                  }
                  effect={address ? "animated-button2" : "animated-button1"}
                  onClick={() => {
                    handleOpenModal();
                    if (address) {
                      setUserAccount();
                    }
                  }}
                />
              </div>
            </Toolbar>
          ) : (
            <Toolbar className={classes.menuMobile}>
              <div className={classes.row1}>
                <div className={classes.main} />
                <Link to="/">
                  <Avatar
                    variant="square"
                    src={logo}
                    style={{ height: 50, width: 50 }}
                  />
                </Link>
                <div className={classes.grow} />
                {["right"].map((anchor) => (
                  <Fragment key={anchor}>
                    <IconButton
                      aria-label="menu"
                      aria-haspopup="true"
                      className={classes.menuIcon}
                      onClick={toggleDrawer(anchor, true)}
                    >
                      <img
                        src={Menu}
                        style={{
                          width: 30,
                          height: 30,
                        }}
                      />
                    </IconButton>
                    <SwipeableDrawer
                      anchor={anchor}
                      disableSwipeToOpen={false}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      onOpen={toggleDrawer(anchor, true)}
                    >
                      {list(anchor)}
                    </SwipeableDrawer>
                  </Fragment>
                ))}
              </div>
            </Toolbar>
          )}
          {openModal ? <ModalConnect setOpen={setOpenModal} /> : null}
          {reward ? <YourRewardPage10 setOpen={setReward} /> : null}
        </AppBar>
      </div>
    </>
  );
};
