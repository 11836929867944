import React, { Fragment, useEffect,useState } from "react";
import "./App.css";

import {
  HomePage01,
  UpgradePage02,
  BuyPage03,
  //MarketPage04,
  CardsPage05,
  BattlePage06,
  PVPPage07,
  PVEPage08,
  StakingPage09,
  YourRewardPage10,
} from "./page/index";

import Adventure11 from "./page/11-Adventure/11-Adventure";
import GemPage13 from "./page/13-Gem-Page/13-Gem-Page";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Navbar } from "./components/index";

import { useStoreApi, contractInit  } from "./services/index";
import Web3 from "web3";
import { useWeb3 } from "./services";
import { useWeb3React } from "@web3-react/core";

function App() {
  console.log(process.env)
  return (
    <>
     
        <Router>
          <ThemeProvider>
            <Fragment>
              <div>
                <Navbar />

                <Switch>
                {/* <Route exact path="/">
                    <GemPage13/>
                  </Route> */}

                  <Route exact path="/upgrade">
                    <UpgradePage02 />
                  </Route>

                  <Route exact path="/">
                    <BuyPage03 />
                  </Route>

                  {/* <Route exact path="/marketpage">
                    <MarketPage04 />
                  </Route> */}

                  <Route exact path="/cardpage">
                    <CardsPage05 />
                  </Route>

                  <Route exact path="/battlepage">
                    <BattlePage06 />
                  </Route>
                  <Route exact path="/pvppage">
                    <PVPPage07 />
                  </Route>
                  <Route exact path="/pvepage">
                    <PVEPage08 />
                  </Route>
                  <Route exact path="/stakingpage">
                    <StakingPage09 />
                
                  </Route>
                  <Route exact path="/adventure">
                    <Adventure11/>
                  </Route>
                  <Route exact path="/market">
                    <Adventure11/>
                  </Route>
                  <Route exact path="/yourrewardpage">
                    <YourRewardPage10/>
                  </Route>
                  
                </Switch>
                {/* <div>
              <Footer/>
            </div> */}
              </div>
            </Fragment>
          </ThemeProvider>
        </Router>
    
    </>
  );
}

export default App;
