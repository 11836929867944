import React from 'react'
import "./FireworkEffect.scss"

export default function FireworkEffect() {
    return (
        <div class="pyro">
            <div class="before"></div>
            <div class="after"></div>
        </div>
    )
}
