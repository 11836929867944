import card1 from "../../assets/1-01.jpg";
import card2 from "../../assets/1-02.jpg";
import card3 from "../../assets/1-03.jpg";
import card4 from "../../assets/1-04.jpg";
import card5 from "../../assets/1-05.jpg";
import card6 from "../../assets/1-06.jpg";
import card7 from "../../assets/2-01.jpg";
import card8 from "../../assets/2-02.jpg";
import card9 from "../../assets/2-03.jpg";
import card10 from "../../assets/2-04.jpg";
import card11 from "../../assets/2-05.jpg";
import card12 from "../../assets/2-06.jpg";
import card13 from "../../assets/3-01.jpg";
import card14 from "../../assets/3-02.jpg";
import card15 from "../../assets/3-03.jpg";
import card16 from "../../assets/3-04.jpg";
import card17 from "../../assets/3-05.jpg";
import card18 from "../../assets/3-06.jpg";
import card19 from "../../assets/4-02.jpg";
import card20 from "../../assets/4-03.jpg";
import card21 from "../../assets/4-04.jpg";
import card22 from "../../assets/4-05.jpg";
import card23 from "../../assets/4-06.jpg";
import card24 from "../../assets/5-01.jpg";
import card25 from "../../assets/5-02.jpg";
import card26 from "../../assets/5-03.jpg";
import card27 from "../../assets/5-04.jpg";
import card28 from "../../assets/5-05.jpg";
import card29 from "../../assets/5-06.jpg";
///boss
import boss1 from "../../assets/Boss/1.jpg";
import boss2 from "../../assets/Boss/2.jpg";
import boss3 from "../../assets/Boss/3.jpg";
import boss4 from "../../assets/Boss/4.jpg";
import boss5 from "../../assets/Boss/5.jpg";
//Theme
import theme1 from "../../assets/Staking-03.jpg";
import theme2 from "../../assets/Staking-02.jpg";
import theme3 from "../../assets/Staking-01.jpg";
import theme4 from "../../assets/Staking-04.jpg";
//frame gem
import frame1 from "../../assets/frame1.png";
import frame2 from "../../assets/frame2.png";
import frame3 from "../../assets/frame3.png";
import frame4 from "../../assets/frame4.png";
import frame5 from "../../assets/frame5.png";
import frame6 from "../../assets/garnet.png"
import frame7 from "../../assets/spinet.png"
//Class
import class0 from "../../assets/Face_Card_Color_07_Logo.png";
import class1 from "../../assets/Face_Card_Color_10_Logo.png";
import class2 from "../../assets/Face_Card_Color_12_Logo.png";
import class3 from "../../assets/Face_Card_Color_09_Logo.png";
import class4 from "../../assets/Face_Card_Color_16_Logo_Crystal.png";
//Rare
import rare1 from "../../assets/NT_336-01.png";
import rare2 from "../../assets/NT_336-02.png";
import rare3 from "../../assets/NT_336-03.png";
import rare4 from "../../assets/NT_336-04.png";
import rare5 from "../../assets/NT_336-05.png";
//Gem
import gem1 from "../../assets/03.png";
import gem2 from "../../assets/01.png";
import gem3 from "../../assets/02.png";
import gem4 from "../../assets/EW-02.png";
import gem5 from "../../assets/EW-01.png";
//ITEM
import item1 from "../../assets/Spinel.svg";
import item2 from "../../assets/Garnet.svg";
export const DataItem = [
  {
    id: 1,
    name: "SPINEL",
    image: item1,
  },
  {
    id: 2,
    name: "GARNET",
    image: item2,
  },
];
export const DataImage = [
  {
    id: 1,
    img: card1,
  },
  {
    id: 2,
    img: card2,
  },
  {
    id: 3,
    img: card3,
  },
  {
    id: 4,
    img: card4,
  },
  {
    id: 5,
    img: card5,
  },
  {
    id: 6,
    img: card6,
  },
  {
    id: 7,
    img: card7,
  },
  {
    id: 8,
    img: card8,
  },
  {
    id: 9,
    img: card9,
  },
  {
    id: 10,
    img: card10,
  },
  {
    id: 11,
    img: card11,
  },
  {
    id: 12,
    img: card12,
  },
  {
    id: 13,
    img: card13,
  },
  {
    id: 14,
    img: card14,
  },
  {
    id: 15,
    img: card15,
  },
  {
    id: 16,
    img: card16,
  },
  {
    id: 17,
    img: card17,
  },
  {
    id: 18,
    img: card18,
  },
  {
    id: 19,
    img: card19,
  },
  {
    id: 20,
    img: card20,
  },
  {
    id: 21,
    img: card21,
  },
  {
    id: 22,
    img: card22,
  },
  {
    id: 23,
    img: card23,
  },
  {
    id: 24,
    img: card24,
  },
  {
    id: 25,
    img: card25,
  },
  {
    id: 26,
    img: card26,
  },
  {
    id: 27,
    img: card27,
  },
  {
    id: 28,
    img: card28,
  },
  {
    id: 29,
    img: card29,
  },
];
export const DataClass = [
  {
    id: 0,
    img: class0,
    name: "earth",
  },
  {
    id: 1,
    img: class1,
    name: "water",
  },
  {
    id: 2,
    img: class2,
    name: "fire",
  },
  {
    id: 3,
    img: class3,
    name: "metal",
  },
  {
    id: 4,
    img: class4,
    name: "wood",
  },
];
export const DataRare = [
  {
    id: 0,
    img: rare1,
  },
  {
    id: 1,
    img: rare2,
  },
  {
    id: 2,
    img: rare3,
  },
  {
    id: 3,
    img: rare4,
  },
  {
    id: 4,
    img: rare5,
  },
];
export const DataBoss = [
  {
    id: 1,
    type: "boss",
    img: boss1,
  },
  {
    id: 2,
    type: "boss",
    img: boss2,
  },
  {
    id: 3,
    type: "boss",
    img: boss3,
  },
  {
    id: 4,
    type: "boss",
    img: boss4,
  },
  {
    id: 5,
    type: "boss",
    img: boss5,
  },
];

export const DataTheme = [
  {
    id: 0,
    img: theme3,
    name: "MEADOW",
    time: 180,
    reward: 20,
  },
  {
    id: 1,
    img: theme2,
    name: "VOLCANO",
    time: 240,
    reward: 40,
  },
  {
    id: 2,
    img: theme1,
    name: "CAVE",
    time: 360,
    reward: 60,
  },
  {
    id: 3,
    img: theme4,
    name: "BEACH",
    time: 480,
    reward: 80,
  },
];

export const DataGemFrame = [
  {
    id: "onyx",
    title: "between 3% and 8%",
    img: frame1,
  },
  {
    id: "calcite",
    title: "between 3% and 8%",
    img: frame2,
  },
  {
    id: "amber",
    title: "between 3% and 8%",
    img: frame3,
  },
  {
    id: "quartz",
    title: "between 3% and 8%",
    img: frame4,
  },
  {
    id: "mysterious",
    title: "between 3% and 8%",
    img: frame5,
  },
  {
    id:'GARNET',
    title:"",
    img:frame6
  },
  {
    id:'SPINEL',
    title:"",
    img:frame7
  }
];

export const DataGem = [
  //cái đầu là onyx ạ, lần lượt đến các gem khác 
  {
    id: 1,
    img: gem1,
    name:'onyx'
  },
  {
    id: 2,
    img: gem3,
    name:'calcite'
  },
  {
    id: 3,
    img: gem2,
    name:'amber'
  },
  {
    id: 4,
    img: gem4,
    name:'quartz'
  },
  {
    id: 5,
    img: gem5,
    name:'mysterious'
  },
];
export const myEffect = [
  {
    id: 1,
    name: "card1",
  },
  {
    id: 2,
    name: "card2",
  },
  {
    id: 3,
    name: "card3",
  },
  {
    id: 4,
    name: "card4",
  },
  {
    id: 5,
    name: "card5",
  },
  {
    id: 6,
    name: "card6",
  },
  {
    id: 7,
    name: "card7",
  },
];
