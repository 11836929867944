import React,{useState, useEffect} from 'react'
import { Footer,FlyEffect } from "../../components";
import { useStyles } from "./style"
import "./style.css"

export default function Adventure11() {
    const classes = useStyles()

    // const [counter, setCounter] =useState(60)

    // useEffect(() => {
    //     counter>0 && setInterval(() => {
    //         setCounter((time) => time-1)
    //     },1000)
    // },[])


    const [seconds, setSeconds] = React.useState(20);
    const [minutes, setMinutes] = React.useState(0);
    const [hours, setHours] = React.useState(18);
    const [days, setDays] = React.useState(80);
    const [timeDone, setDone] = React.useState(false);
    //SECONDS
  
    //seconds 60 -> 0
    useEffect(() => {
      const secondsCounter =
        seconds > 0 && setInterval(() => setSeconds(seconds - 1), 1000);
      return () => clearInterval(secondsCounter);
    }, [seconds]);
  
    //seconds == 60 (when seconds == 0)
    useEffect(() => {
      return () => setSeconds(60);
    }, [seconds == 0 && !timeDone ]);
  
    //MINUTES
  
    //Minutes 60 -> 0  (when seconds == 0)
    useEffect(() => {
      return () => setMinutes(minutes-1)
    }, [seconds == 0]);
  
    //Minutes == 60 (when minutes == 0)
    useEffect(() => {
      return () => setMinutes(60);
    }, [minutes == 0 && !timeDone]);
  
    //HOURS
  
    //hours 24 => 0 (when minutes == 0)
    useEffect(() => {
      return () => setHours(hours - 1);
    }, [minutes == 0]);
  
    //hours == 23 (when hours == 0)
    useEffect(() => {
      return () => setHours(23);
    }, [hours == 0 && !timeDone]);
  
    //DAYS
    //days 5 => 0 (when hours == 0)
    useEffect(() => {
        return () => setDays(days-1);
    }, [hours == 0 && !timeDone])
  
  //DONE
  
    useEffect(() => {
        return () => setDone(true)
    }, [days == 0 && hours == 0 && minutes == 0 && seconds == 0])

    return (
        <div className="cover">
          <FlyEffect/>
            <div className={classes.body}>
                <div class="middle font-face-gm">
                    <h1>COMING SOON</h1>
                    <hr/>
                    <p style={{fontSize:'30px'}}>{days}d : {hours}h : {minutes}m : {seconds}s</p>
                </div>
            </div>
           <div>
               <Footer/>
           </div>
        </div>
    )
}
