import React, { useState, useEffect } from "react";
import {
  Footer,
  GreyButton,
  WoodButton,
  CountDownTimer,
  ModalError,
  PopupGem,
} from "../../components";
import { useStyles } from "./style";
import LazyLoad from "react-lazyload";
import Img from "react-cool-img";

import {
  useStoreApi,
  getStakeStatus,
  getAllCARD,
  Stake,
  DataImage,
  DataClass,
  DataRare,
  UnStake,
  getStakeTime,
  DataGem,
  myEffect,
} from "../../services";
import frame from "../../assets/login board + buttons/LGBT-02.png";
import clsx from "clsx";
import ModalConnect from "../../components/ModalConnect/ModalConnect";
import { PopupCard, FlyEffect } from "../../components/index";
import { DataTheme } from "../../services";
import "../../styles/globalStyle.css";
import "./style.css";
import "../../styles/rainbowEffect.css";
import "./style-Staking-Page.scss";
import ModalApprove from "../../components/ModalApprove/ModalApprove";

import "../../styles/ButtonSpinner.css";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import ModalDynamic from "../../components/ModalDynamic/ModalDynamic";

const CardComponent = React.memo(
  ({ item, index, onClick, allowance, refresh }) => {
    const classes = useStyles();
    const [imgCard, setImgCard] = useState("");
    const [imgClass, setImgClass] = useState("");
    const [imgCounter, setImgCounter] = useState("");
    const [imgRare, setImgRare] = useState("");
    const [effect, setEffect] = useState("");
    const [time, setTime] = useState("");
    const [status, setStatus] = useState(false);
    const checkCard = () => {
      DataImage.map((data) => {
        if (data.id === Number(item.idImg)) {
          setImgCard(data.img);
        }
      });
    };
    const checkStatus = async () => {
      let status = await getStakeStatus(item.id);
      setStatus(status);
    };
    const checkClass = () => {
      DataClass.map((data) => {
        if (data.id === Number(item.class)) {
          setImgClass(data.img);
        }
        if (data.name === item.counter) {
          setImgCounter(data.img);
        }
      });
    };
    const checkRare = () => {
      DataRare.map((data) => {
        if (data.id === Number(item.rare)) {
          //console.log(data.id);
          setImgRare(data.img);
        }
      });
    };
    const randomEffect = () => {
      let effectcard = myEffect[Math.floor(Math.random() * myEffect.length)];
      return effectcard.name;
    };
    const checkEffect = () => {
      if (Number(item.rare) === 0 || Number(item.rare) === 1) {
        setEffect("card0");
      }
      if (Number(item.rare) === 2 || Number(item.rare) === 3) {
        let effectCard = randomEffect();
        setEffect(effectCard);
      }
      if (Number(item.rare) === 4) {
        setEffect("rainbow");
      }
    };
    const [displayBtn, setDisplayBtn] = useState(true);
    const checkTimeStake = async () => {
      try {
        let timeStamp = await getStakeTime(item.id).then(async (res) => res);
        //console.log(timeStamp)
        if (Number(timeStamp) * 1000 > Date.now()) {
          setDisplayBtn(false);
          setTime(Number(timeStamp));
        }
      } catch (error) {
        //console.log(error);
      }
    };
    const [reload, setReload] = useState(false);
    useEffect(() => {
      checkCard();
      checkClass();
      checkRare();
      checkEffect();
      checkStatus();
      checkTimeStake();
    }, [refresh]);
    return (
      <>
        {!status ? null : (
          <div>
            <div style={{ padding: 32 }} key={index}>
              <div
                className={effect}
                style={{
                  position: "relative",
                  color: "white",
                  height: 328,
                }}
              >
                <Img
                  className="gemPurple"
                  src={imgClass}
                  style={{
                    position: "absolute",
                    width: 32,
                    height: 32,
                    marginLeft: 85,
                    marginTop: 190,
                    zIndex: 1,
                  }}
                />
                <Img src={imgCard} className={classes.imgCard} />
                <Img
                  style={{
                    position: "absolute",
                    width: "22px",
                    top: 14,
                    left: 11,
                  }}
                  src={imgRare}
                  className="numberCard3-popup"
                />
                <div className="img-content-popup">
                  <div className="img-content-popup-left">
                    <p>POWER : {item.power}</p>
                    <p>DEFENCE : {item.defence}</p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <div>COUNTER : </div>
                      <Img
                        className="gemPurple-couter-popup-left"
                        src={imgCounter}
                        style={{
                          width: "14px",
                          height: "14px",
                        }}
                      />
                    </div>
                    <p>BONUS REWARD : {item.reward}</p>
                  </div>
                  <div className="img-content-popup-right">
                    <p>LEVEL : {item.level}</p>
                    <p>SS : {item.exp}</p>
                    <p>TURN : {item.turn}</p>
                  </div>
                </div>
                {!displayBtn && (
                  <div className={classes.notiCT}>
                    <div> Staking time </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                {displayBtn ? (
                  <div className={classes.containBtn}>
                    <GreyButton
                      title={allowance || reload ? "UNSTAKE" : "APPROVE"}
                      onClick={onClick}
                    />
                  </div>
                ) : (
                  <div style={{ margin: 16, textAlign: "center" }}>
                    <CountDownTimer
                      eventTime={time}
                      type="STAKING"
                      refresh={setReload}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
const CardTheme = ({ item, index, allowance, onClick }) => {
  const classes = useStyles();
  const [effect, setEffect] = useState("");
  const randomEffect = () => {
    let effectcard = myEffect[Math.floor(Math.random() * myEffect.length)];
    return effectcard.name;
  };
  const createEffect = () => {
    switch (item.name) {
      case "MEADOW":
        setEffect("card0");
        break;
      case "VOLCANO":
        let random = randomEffect();
        setEffect(random);
        break;
      case "CAVE":
        let random2 = randomEffect();
        setEffect(random2);
        break;
      case "BEACH":
        setEffect("rainbow");
        break;
    }
  };
  useEffect(() => {
    createEffect();
  }, []);
  return (
    <div style={{ padding: 32 }}>
      <div key={index} className={effect}>
        <div
          className={
            effect === "rainbow" ? classes.themerandom2 : classes.themerandom
          }
        >
          <Img src={item.img} className={clsx("imgCard", classes.imgCard)} />
          <div className={classes.containContent}>
            <div className={classes.containTheme}>{item.name}</div>
            <div className={classes.stakingTime}>
              Staking time: {item.time} minutes
            </div>
            <div className={classes.stakingReward}>
              Reward: {item.reward} SS
            </div>
          </div>
        </div>
      </div>
      <div className={classes.containBtn}>
        <GreyButton title={allowance ? "STAKE" : "APPROVE"} onClick={onClick} />
      </div>
    </div>
  );
};
export const StakingPage09 = () => {
  const classes = useStyles();
  const { address, allowance } = useStoreApi();
  const [openGem, setOpenGem] = useState(false);
  const [popupCard, setPopupCard] = useState("");
  const [openCard, setOpenCard] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [noti, setNoti] = useState(false);
  const onStaking = (item) => {
    if (address) {
      setPopupCard(item.id);
      setOpenCard(true);
    } else {
      setOpenModal(true);
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [refresh, setRefresh] = useState(false);

  const selectCardStake = async (item) => {
    setRefresh(true);
    try {
      await Stake(item.id, popupCard, address).then((res) => {
        setOpenCard(false);
        if (res) {
          setNoti(true);
          if (res.status) {
            setStatus("Your card has been successfully staked");
          } else {
            setStatus("Your card has not been staked");
          }

          setRefresh(false);
        }
      });
    } catch (err) {
      //console.log(err);
      setError(true);
      setTitle("Your card has not been staked");
      setRefresh(false);
    }
  };
  //console.log(refresh)
  const [YourCard, setYourCard] = useState([]);
  const getDataCard = async () => {
    setIsLoading(true);
    try {
      if (address) {
        let snapshot = await getAllCARD(address).then((res) => res);
        let resultData = snapshot.filter((item) => Number(item.bornTime) !== 0);
        const checkList = [
          ...new Map(
            resultData.map((item) => [JSON.stringify(item), item])
          ).values(),
        ];
        setYourCard(checkList);
      }
      setIsLoading(false);
    } catch (err) {
      //console.log(err);
      setIsLoading(false);
    }
  };
  const onClickUnstake = async (item) => {
    setRefresh(true);
    await UnStake(item.id, gem.id !== undefined ? gem.id : gem, address)
      .then((res) => {
        setOpenCard(false);
        if (res) {
          setNoti(true);
          if (res.status) {
            setStatus("Your card has been successfully unstaked");
          } else {
            setStatus("Your card has not been unstaked");
          }
        }
        setRefresh(false);
      })
      .catch((err) => {
        //console.log(err);
        setError(false);
        setTitle("Your card has not been unstaked");
        setRefresh(false);
      });
  };
  const checkLogout = () => {
    if (!address) {
      setYourCard([]);
      setPopupCard("");
      setOpenGem("");
    }
  };
  const [onPopupGem, setOnPopupGem] = useState(false);
  const [gem, setGem] = useState(0);
  const [error, setError] = useState(false);
  const [title, setTitle] = useState("");
  const onSelectGem = (item) => {
    if (Number(item.gem) === 1) {
      let result = DataGem.filter((data) => data.name === item.idImg);
      let object = result[0];
      object.idGem = item.id;
      setGem(object);
    } else {
      setTitle("You can't choose this gem");
      setError(true);
    }
  };
  useEffect(() => {
    checkLogout();
  }, [address]);
  useEffect(() => {
    getDataCard();
  }, [address, refresh]);
  return (
    <>
      <div className="cover font-face-gm">
        <FlyEffect />
        <div className={openGem ? "body-stakingPage" : "body-stakingPage2"}>
          {/**GEM*/}

          {/** */}
          {/**Card */}

          <div className={classes.containCard}>
            {/**1 */}
            {DataTheme.map((item, index) => (
              <LazyLoad>
                <CardTheme
                  item={item}
                  index={index}
                  allowance={allowance}
                  onClick={() => onStaking(item)}
                />
              </LazyLoad>
            ))}
          </div>
        </div>
        <div>
          <div className={classes.containTheme2}>
            <div>
              <WoodButton title="STAKING" />
            </div>
            <div className="note-title">
              ( Use Calite Gem to earn more SS!! )
            </div>
          </div>
          <div className={classes.frameBtn} onClick={() => setNoti(true)}>
            YOUR GEM
          </div>
          <div className={classes.frameBtn}>
            <Img src={frame} className={classes.frameImg} />
            {gem !== 0 ? (
              <div
                onClick={() => {
                  if (address) {
                    setOnPopupGem(true);
                  } else {
                    setOpenModal(true);
                  }
                }}
              >
                <img src={gem.img} className={classes.gem} />
              </div>
            ) : (
              <div
                className={classes.titleImg}
                onClick={() => {
                  if (address) {
                    setOnPopupGem(true);
                  } else {
                    setOpenModal(true);
                  }
                }}
              >
                Choose
              </div>
            )}
          </div>
          <div className={classes.containCard}>
            {YourCard.map((item, index) => (
              <LazyLoad>
                <CardComponent
                  item={item}
                  index={index}
                  allowance={allowance}
                  onClick={() => onClickUnstake(item)}
                  refresh={refresh}
                  //type={type}
                />
              </LazyLoad>
            ))}
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </div>
      {openModal ? (
        <ModalConnect type="another" setOpen={setOpenModal} />
      ) : null}
      {/* {openModal && <ModalConnect setOpen={setOpenModal} />} */}
      {!allowance ? <ModalApprove /> : null}
      {refresh ? <LoadingPage /> : null}
      {noti ? <ModalDynamic title={status} onClick={setNoti} /> : null}
      {openCard && (
        <PopupCard
          type="STAKING"
          setOpen={setOpenCard}
          selectCard={selectCardStake}
          open={openCard}
        />
      )}
      {error && <ModalError onClick={setError} errors={title} />}
      {onPopupGem && (
        <PopupGem
          setOpen={setOnPopupGem}
          selectGem={onSelectGem}
          open={onPopupGem}
        />
      )}
    </>
  );
};
