import { makeStyles } from '@material-ui/core/styles';
import frameWallet from "../../assets/03-02.png"

export const useStyles = makeStyles((theme) => ({
    containList: {
        display: 'flex',
        flexDirection: 'row',
        zIndex:88,
        justifyContent: 'space-between',
        alignItems:'center',
        background:"rgba(255,255,255,0.9)",
        padding:"4px 20px",
        borderRadius:10,
        marginBottom:16,
        width:"100%",
        maxWidth:300,
        border:"none",
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
            transition: 'all 0.2s ease-in-out',
            boxShadow:"1px 1px 4px 4px #006666",
            background:"#000"
        }
    },
    iconMeta: {
        width: 20,
        height: 20,
    },
    containIcon: {
        background: "white",
        borderRadius:100,
        padding:"6px 8px"
    },
    titleBtn: {
       marginRight:20,
       color:"orange",
       fontFamily:"Jersey M54"
    },
    modal:{
        position: 'fixed', /* Stay in place */
        zIndex: 88, /* Sit on top */
        
        left: 0,
        top: 0,
        width: '100%', /* Full width */
        height: '100%', /* Full height */
        overflow: 'auto', /* Enable scroll if needed */
        backgroundColor: 'rgb(0,0,0)', /* Fallback color */
        backgroundColor: 'rgba(0,0,0,0.4)', /* Black w/ opacity */
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    containHeader:{
        display:'flex',
        flexDirection:'row',
        justifyContent:"space-between",
        marginBottom:24
    },

    broad:{
        color: 'white',
        backgroundImage: `url(${frameWallet})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: 'contain',
        backgroundPosition: "center",
        height: 300,
        width:500,
        display:"flex",
        justifyContent:"center",
        flexDirection:"column",
        alignItems:"center",
        padding:20,
        
        [theme.breakpoints.down('sm')]: {
          display:'flex',
          alignItems:'center',
          textAlign:'center',
        },
    },
    buttonLogout:{
        position:"relative",
        textAlign:"center"
    },
    btn:{
        border: "none"
    },
    imgBtn:{
       width:"100%",
       height:"auto",
       maxWidth:200
    },
    logout:{
        position:"absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    title:{
        [theme.breakpoints.down('sm')]: {
            fontSize:13
        },
    }
}))