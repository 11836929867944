import React, { useEffect, useState } from "react";
import "./modalResult.css";
import boardResult from "../../assets/03-02.png";
import greyBtn from "../../assets/button/greyBtn.png";
import { DataItem, GetItem } from "../../services";

export default function ModalResult({ onClose, onClick, result, reset }) {
  // WIN,
  // REWARD,
  // BONUS,
  //console.log(result.ITEM);
  const [item, setItem] = useState("");
  const getGemResult = async () => {
    if (result.ITEM !== undefined) {
      if (result.ITEM > 0) {
        //let snapshot =
        let snapshot = await GetItem(result.ITEM).then(async (res) => res);
        // console.log(typeof snapshot)
        // console.log('dfdsfsd',snapshot)
        let filter = DataItem.filter((data) => data.name === snapshot[2]);
        if(filter.length !== 0){
          setItem(filter[0].image);
        }
      }
    }
  };
  useEffect(() => {
    getGemResult();
  }, []);
  return (
    <div
      className="containModal"
      onClick={() => {
        onClick(false);
        reset();
      }}
    >
      <div className="board-result">
        <img src={boardResult} alt="" className="board-result-img" />
        <div className="board-result-content">
          <div className="board-result-content-top">
            <h2 className="board-result-content-title">RESULT</h2>
            <div className="board-result-content-subtitle">
              <div className="board-result-content-subtitle-left">
                <p className="title">BATTLE</p>
                <p className="title">REWARD </p>
                <p className="title">
                  {result.BONUS === undefined ? "ITEM" : "BET"}
                </p>
                <p className="title">
                  {result.BONUS === undefined ? "" : "BONUS"}{" "}
                </p>
                <p className="title">TOTAL EARN</p>
              </div>
              <div className="board-result-content-subtitle-right">
                <p className="title">{result.WIN === 0 ? "WIN" : "LOSE"}</p>
                <p className="title">
                  {result.WIN === 1
                    ? 0
                    : result.REWARD.toFixed(1).replace(
                        /\d(?=(\d{3})+\.)/g,
                        "$&,"
                      ) +
                      " " +
                      "SS"}
                </p>
                {result.BONUS !== undefined ? (
                  result.BONUS === 0 ? (
                    <p className="title">LOSE</p>
                  ) : (
                    <p className="title">WIN</p>
                  )
                ) : result.ITEM == 0 ? (
                  <p className="title">0</p>
                ) : (
                  <div>
                    <img
                      src={item}
                      style={{
                        width: "100%",
                        height: "auto",
                        maxWidth: 25,
                        marginTop:4
                      }}
                    />
                  </div>
                )}
                <p className="title">
                  {result.BONUS !== undefined &&
                    result.BONUS.toFixed(1).replace(
                      /\d(?=(\d{3})+\.)/g,
                      "$&,"
                    ) + "SS"}
                </p>
                <p className="title">
                  {result.WIN === 1
                    ? 0
                    : result.BONUS !== undefined
                    ? (result.REWARD + result.BONUS)
                        .toFixed(1)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,") + "SS"
                    : result.REWARD.toFixed(1).replace(
                        /\d(?=(\d{3})+\.)/g,
                        "$&,"
                      ) + " EMP"}
                </p>
              </div>
            </div>
          </div>
          <div className="board-result-content-bottom">
            <button className="board-result-content-greyBtn">
              <img className="board-result-content-greyBtn-img" src={greyBtn} />
              <div
                className="board-result-content-greyBtn-content"
                onClick={() => {
                  onClose(false);
                  reset();
                }}
              >
                CLOSE
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
