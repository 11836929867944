import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  containBtn2:{
    display: "flex",
    justifyContent: "center",
    margin:64,
  },
  containBtn3:{
    display: "flex",
    justifyContent: "center",
    margin:48,
    [theme.breakpoints.down("sm")]:{
      margin:16,
    }
  },
  containModal:{
    position: 'fixed', /* Stay in place */
    zIndex:88, /* Sit on top */
    left: 0,
    top: 0,
    width: '100%', /* Full width */
    height: '100%', /* Full height */
    overflow: 'auto', /* Enable scroll if needed */
    backgroundColor: 'rgb(0,0,0)',/* Fallback color */
    backgroundColor: 'rgba(0,0,0,0.8)', /* Black w/ opacity */
    display: 'flex',
    justifyContent: 'center'
  },
  imgCard:{
    width: '100%',
    height: 'auto',
    maxWidth: '200px', 
    borderRadius:10
  },
  imgCard2:{
    width: '100%',
    height: 'auto',
    maxWidth: '250px', 
    borderRadius:10
  },
  broad:{   
    color: "black",
    padding:20,
    borderRadius:6,
    marginTop: '179px'
  },
  row:{
    display:"flex",
    flexDirection:"row",
    alignItems:'center'
  },
  icon:{
    width: 25,
    height: 25,
    marginLeft:8
  },
  btnWin:{
    background: "linear-gradient(to top,#6a1111,#711414,#801a1a,#a72929,#cd3838,#fa4a4a)",
    padding: "8px 64px 8px 64px",
    fontSize: "1.5vmin",
    color: 'white',
    display:'flex',
    fontFamily:"Jersey M54",
    transition: 'all 0.2s ease-in-out',
    border:"none",
    borderRadius:6,
    '&:hover': {
        transition: 'all 0.2s ease-in-out',
        textShadow: "2px 2px 8px #fff"
    },
  },
  info:{
    position:'absolute',
    fontFamily:"Jersey M54",
    marginLeft:32,
    color: "white",
    color: "white",
    width: "100%",
    bottom: 50,
  },
  containCard: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems:'center'
  },
  content:{
    fontSize:"3vmin",
    color:'#fff'
  }
}))