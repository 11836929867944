import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import {
  Footer,
  FlyEffect,
  PopupCard,
  GreyButton,
  WoodButton,
  PopupGem,
  ModalError,
} from "../../components";
import Img from "react-cool-img";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import { CountDownTimer } from "../../components";
import { useStyles } from "./style";
import "../../styles/BorderEffect.css";
import "../../styles/globalStyle.css";
import "../../styles/rainbowEffect.css";
import "./style-PVE-Page.scss";
import "./style.css";
import {
  DataImage,
  DataBoss,
  getAllCARD,
  DataGem,
  myEffect,
} from "../../services";
import "../../styles/ButtonSpinner.css";
import frame from "../../assets/login board + buttons/LGBT-02.png";
import { useStoreApi, DataClass, DataRare, Fight } from "../../services";
import ModalApprove from "../../components/ModalApprove/ModalApprove";
import bossEmpty from "../../assets/card.png";
import ModalConnect from "../../components/ModalConnect/ModalConnect";
import ModalResult from "../../components/ModalResult/ModalResult";
import vsImg from "../../assets/vs-01.png";
import LazyLoad from "react-lazyload";

const CardComponent = React.memo(({ item, index, onClick }) => {
  const classes = useStyles();
  const [imgCard, setImgCard] = useState("");
  const [imgClass, setImgClass] = useState("");
  const [imgCounter, setImgCounter] = useState("");
  const [imgRare, setImgRare] = useState("");
  const [effect, setEffect] = useState("");
  const [time, setTime] = useState("");
  const checkCard = () => {
    DataImage.map((data) => {
      if (data.id === Number(item.idImg)) {
        setImgCard(data.img);
      }
    });
  };

  const checkClass = () => {
    DataClass.map((data) => {
      if (data.id === Number(item.class)) {
        setImgClass(data.img);
      }
      if (data.name === item.counter) {
        setImgCounter(data.img);
      }
    });
  };
  const checkRare = () => {
    DataRare.map((data) => {
      if (data.id === Number(item.rare)) {
        //console.log(data.id);
        setImgRare(data.img);
      }
    });
  };
  const randomEffect = () => {
    let effectcard = myEffect[Math.floor(Math.random() * myEffect.length)];
    return effectcard.name;
  };
  const checkEffect = () => {
    if (Number(item.rare) === 0 || Number(item.rare) === 1) {
      setEffect("card0");
    }
    if (Number(item.rare) === 2 || Number(item.rare) === 3) {
      let effectCard = randomEffect();
      setEffect(effectCard);
    }
    if (Number(item.rare) === 4) {
      setEffect("rainbow");
    }
  };
  const [notiCard, setNotiCard] = useState("");
  const getTimeCountDown = () => {
    let countDown = 0;
    let turn = [3, 4, 5, 7, 9];
    turn.map((data, index) => {
      if (Number(item.turn) < data && Number(item.rare) === index) {
        countDown = Number(item.lastplayedTime) + 3600;
        ///Waiting for next time
        setNotiCard("Waiting for next time");
      }

      if (Number(item.rare) === index && Number(item.turn) === data) {
        let date = new Date(Number(item.lastplayedTime) * 1000).setUTCHours(
          0,
          0,
          0,
          0
        );
        // console.log("date", date);
        //next day
        let today = new Date().setUTCHours(0, 0, 0, 0);
        setNotiCard("Waiting for next day");
        //console.log("today", today);
        if (date === today) {
          // console.log("here");
          let tomorrow = new Date(today).getTime() + 24 * 60 * 60 * 1000;
          countDown = Number(tomorrow) / 1000;
        }
      }
    });

    setTime(Number(countDown));
  };
  useEffect(() => {
    checkCard();
    checkClass();
    checkRare();
    checkEffect();
    getTimeCountDown();
  }, []);
  return (
    <div style={{ flexDirection: "column", margin: 4 }} key={index}>
      <div style={{ padding: 32 }}>
        <div
          key={index}
          //className={`${effect}`}
        >
          <div
            style={{
              position: "relative",
              color: "white",
              height: 333,
            }}
            className={effect}
          >
            <Img
              className="gemPurple-stakingPage"
              src={imgClass}
              style={{
                position: "absolute",
                width: 32,
                height: 32,
                marginLeft: 85,
                marginTop: 192,
                zIndex: 1,
              }}
            />
            <Img src={imgCard} className={clsx(classes.imgCard)} />
            <Img
              style={{
                position: "absolute",
                width: "24px",
                top: 12,
                left: 11,
              }}
              src={imgRare}
              className="numberCard3-stakingPage"
            />

            <div className="img-content-cardPage">
              <div className="img-content-stakingPage-left">
                <p>POWER : {item.power}</p>
                <p>DEFENCE : {item.defence}</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div>COUNTER : </div>
                  {imgCounter !== "" && (
                    <img
                      className="gemPurple-couter-cardPage"
                      src={imgCounter}
                      style={{
                        width: "14px",
                        height: "14px",
                      }}
                    />
                  )}
                </div>
                <p>BONUS REWARD : {item.reward}</p>
              </div>
              <div className="img-content-stakingPage-right">
                <p>LEVEL : {item.level}</p>
                <p>EXP : {item.exp}</p>
                <p>TURN : {item.turn}</p>
              </div>
            </div>
            <div className={classes.notiCT}>
              <div> {notiCard} </div>
            </div>
          </div>
        </div>

        {/* <div className={classes.containBtn}>
        <GreyButton title="SELL" onClick={onClick} />
      </div> */}
      </div>
      <div style={{ margin: 16, textAlign: "center" }}>
        <CountDownTimer eventTime={time} />
      </div>
    </div>
  );
});
export const PVEPage08 = () => {
  const classes = useStyles();
  const [cardInfo, setCardInfo] = useState("");
  const [cardBoss, setCardBoss] = useState("");
  const [noti,setNoti] = useState()
  const randomCardnBoss = () => {
    let boss = DataBoss[Math.floor(Math.random() * DataBoss.length)];

    return boss;
  };

  const [loading, setLoading] = useState(false);
  const [betwin, setBetwin] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState(false);
  const betWinCard = async (type) => {
    //setLoading(true);
    setRefresh(true);
    try {
      await Fight(cardInfo.id, type, gem.id !== undefined ? gem.id : gem, address).then(
        
        async (res) => {
          if (res) {
            let bossCard;
            let bet;
            //console.log('dfsdf',res.logs)
            if(res.logs !== undefined){
              bossCard = formatStringBoss(res.logs[res.logs.length-1].data);
              bet = formatStringResult(res.logs[res.logs.length-1].data);
            }
            // if (res.logs.length === 1) {
            //   bossCard = formatStringBoss(res.logs[0].data);
            //   bet = formatStringResult(res.logs[2].data);
            // }
            // if (res.logs.length === 2) {
            //   bossCard = formatStringBoss(res.logs[1].data);
            //   bet = formatStringResult(res.logs[2].data);
            // }
            // if (res.logs.length === 3) {
            //   bossCard = formatStringBoss(res.logs[2].data);
            //   bet = formatStringResult(res.logs[2].data);
            // }
            // if (res.logs.length === 4) {
            //   bossCard = formatStringBoss(res.logs[3].data);
            //   bet = formatStringResult(res.logs[3].data);
            // }

            let imgClass = DataClass.filter(
              (item) => item.id === bossCard.Boss_class
            );
            let imgRare = DataRare.filter(
              (item) => item.id === bossCard.Boss_Rare
            );

            let random = randomCardnBoss();
            bossCard.img = random.img;
            bossCard.Boss_class = imgClass[0].img;
            bossCard.Boss_Rare = imgRare[0].img;
            setCardBoss(bossCard);
            setBetwin(bet);
            if (bet) {
              setOpenResult(true);
            }
            //setLoading(false);
            await getDataCard();
            setRefresh(false);
          }
        }
      );
    } catch (err) {
      //console.log('kkkk',err);
      setNoti("Bet is failed")
      setRefresh(false);
      setError(true);
    }
  };

  const formatStringBoss = (Data) => {
    //Boss
    let Boss_Rare = parseInt(Data.substring(2, 66), 16);
    let Boss_Power = parseInt(Data.substring(66, 130), 16);
    let Boss_Defence = parseInt(Data.substring(130, 194), 16);
    let Boss_class = parseInt(Data.substring(194, 258), 16);
    let newBoss = {
      Boss_Rare,
      Boss_Power,
      Boss_Defence,
      Boss_class,
    };
    return newBoss;
  };
  const formatStringResult = (Data) => {
    let WIN = parseInt(Data.substring(322, 386), 16);
    let REWARD = parseInt(Data.substring(386, 450), 16);
    let BONUS = parseInt(Data.substring(450, 514), 16);
    let bet = {
      WIN,
      REWARD,
      BONUS,
    };
    //console.log('alo',bet)
    return bet;
  };
  const [openModal, setOpenModal] = useState(false);
  const { address, allowance } = useStoreApi();
  const checkLogout = () => {
    if (!address) {
      setCardBoss("");
      setBetwin("");
      setCardInfo("");
      setYourCard([]);
    }
  };

  useEffect(() => {
    randomCardnBoss();
  }, []);
  const [onPopupCard, setOnPopupCard] = useState(false);
  const onClickSelectCard = (card) => {
    setCardBoss("");
    setRefresh(true);
    if (!loading) {
      let imgCard;
      let rare;
      let effect;
      let classImg;
      let counter;
      setOnPopupCard(false);
      DataImage.map((data) => {
        if (data.id === Number(card.idImg)) {
          imgCard = data.img;
        }
      });
      DataClass.map((data) => {
        if (data.id === Number(card.class)) {
          classImg = data.img;
        }
        if (data.name === card.counter) {
          counter = data.img;
        }
      });
      DataRare.map((data) => {
        if (data.id === Number(card.rare)) {
          rare = data.img;
        }
      });
      if (Number(card.rare) === 0 || Number(card.rare) === 1) {
        effect = "card0";
      }
      if (Number(card.rare) === 2 || Number(card.rare) === 3) {
        let effectCard = randomEffect();
        effect = effectCard;
      }
      if (Number(card.rare) === 4) {
        effect = "rainbow";
      }
      let newCard = {
        imgCard,
        rare,
        effect,
        classImg,
        counter,
        power: card.power,
        defence: card.defence,
        reward: card.reward,
        level: card.level,
        exp: card.exp,
        turn: card.turn,
        id: card.id,
      };

      setCardInfo(newCard);
      setRefresh(false);
    }
  };
  const randomEffect = () => {
    let effectcard = myEffect[Math.floor(Math.random() * myEffect.length)];
    return effectcard.name;
  };
  const [openResult, setOpenResult] = useState(false);
  const [YourCard, setYourCard] = useState([]);

  const getDataCard = async () => {
    try {
      setLoading(true);
      let snapshot = await getAllCARD(address).then(async (res) => res);
      let checkTime = snapshot.filter((item) => Number(item.bornTime) > 0);
      let resultData = checkTime.filter((item) => conditionDisplay(item));
      const checkList = [
        ...new Map(
          resultData.map((item) => [JSON.stringify(item), item])
        ).values(),
      ];
      setYourCard(checkList);
      setLoading(false);
    } catch (err) {}
  };

  //When a card is in countdown or reached the maximum turn per day
  // This function will display it on COUNTDOWN
  const conditionDisplay = (item) => {
    //let newDate = false;
    let display;
    let turn = [3, 4, 5, 7, 9];

    turn.map((data, index) => {
      if (
        (Number(item.turn) < data &&
          Number(item.rare) === index &&
          Number(item.lastplayedTime) > Number(Date.now() / 1000) - 3600) ||
        (Number(item.rare) === index && Number(item.turn) === data)
      ) {
        let date = new Date(Number(item.lastplayedTime) * 1000).setUTCHours(
          0,
          0,
          0,
          0
        );
        let today = new Date().setUTCHours(0, 0, 0, 0);
        if (Number(today) > Number(date)) {
          display = false;
          //console.log(newDate);
        } else {
          display = true;
        }
      }
    });
    return display;
  };
  const [onPopupGem, setOnPopupGem] = useState(false);
  const [gem, setGem] = useState(0);
  const onSelectGem = (item) => {
    //console.log('alo',item)
    if (Number(item.gem) === 0) {
      let result = DataGem.filter((data) => data.name === item.idImg);
      let object = result[0]
      object.idGem = item.id
      //console.log('test',object)
      setGem(object);
    } else {
      setError(true);
    }
  };
  useEffect(() => {
    checkLogout();
  }, [address]);
  useEffect(() => {
    getDataCard();
  }, [address, refresh]);
  return (
    <div className="cover font-face-gm">
      <FlyEffect />
      <div className="body-pvePage">
        {/**GEM*/}

        {/* </div> */}
        {/** */}
        {/**Card */}

        <div className={classes.containCard}>
          {/**1 */}
          <div className="container-img">
            {cardInfo == "" ? (
              <div
                className={clsx("emptyImg", classes.emptyImg)}
                onClick={() => {
                  if (address) {
                    setOnPopupCard(true);
                  } else {
                    setOpenModal(true);
                  }
                }}
              >
                <div>Choose</div>
              </div>
            ) : (
              <div
                className={` ${cardInfo.effect}`}
                style={{
                  position: "relative",
                  // display: "flex",
                  // justifyContent: "center",
                  height: 333,
                }}
                onClick={() => {
                  if (address) {
                    if (!loading) {
                      setOnPopupCard(true);
                    }
                  } else {
                    setOpenModal(true);
                  }
                }}
              >
                <img
                  style={{
                    position: "relative",
                    // display: "flex",
                    // justifyContent: "center",
                    height: 333,
                  }}
                  onClick={() => {
                    if (address) {
                      if (!loading) {
                        setOnPopupCard(true);
                      }
                    } else {
                      setOpenModal(true);
                    }
                  }}
                />
                <img src={cardInfo.imgCard} className={classes.imgCard} />
                <img
                  style={{
                    position: "absolute",
                    width: "24px",
                    bottom: 292,
                    left: 10,
                  }}
                  src={cardInfo.rare}
                  className="numberCard3-pvePage-left"
                />

                <img
                  className="gemPurple-pvePage"
                  src={cardInfo.classImg}
                  style={{
                    position: "absolute",
                    width: "32px",
                    height: "32px",
                    bottom: 110,
                    left: 83,
                  }}
                />

                <div className="img-content-pvePage">
                  <div className="img-content-pvePage-left">
                    <p>POWER : {cardInfo.power}</p>
                    <p>DEFENCE : {cardInfo.defence}</p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <div>COUNTER : </div>
                      {cardInfo.counter !== undefined && (
                        <img
                          className="gemPurple-couter-pvePage-left"
                          src={cardInfo.counter}
                          style={{
                            width: "14px",
                            height: "14px",
                          }}
                        />
                      )}
                    </div>
                    <p>BONUS REWARD : {cardInfo.reward}</p>
                  </div>
                  <div className="img-content-pvePage-right">
                    <p>LEVEL : {cardInfo.level}</p>
                    <p>EXP : {cardInfo.exp}</p>
                    <p>TURN : {cardInfo.turn}</p>
                  </div>
                </div>
              </div>
            )}

            {cardBoss == "" ? (
              <div className={classes.containBtn}>
                <GreyButton
                  title="WIN"
                  onClick={() => {
                    if (cardInfo !== "") {
                      //thẻ trái
                      betWinCard(0);
                    }
                  }}
                />
              </div>
            ) : null}
          </div>
          <div className={clsx("columnBtn", classes.columnBtn)}>
            <div className={classes.containBtn2}>
              <div className={classes.VSview}>
                <img src={vsImg} alt="" className={classes.vsImg} />
              </div>

              {
                <div className={classes.containBtn}>
                  <GreyButton title="BOSS" />
                </div>
              }
            </div>
          </div>
          {cardBoss == "" ? (
            <>
              <div>
                <div className="img-random flip-bossImg-front">
                  <Img
                    src={bossEmpty}
                    className={clsx("bossEmpty", classes.imgCard)}
                  />
                </div>
                {cardBoss == "" ? (
                  <div className={classes.containBtn}>
                    <GreyButton
                      title="WIN"
                      onClick={() => {
                        if (cardInfo !== "") {
                          betWinCard(1);
                        }
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <div>
              <div
                className="img-random rainbow flip-bossImg-back"
                style={{
                  position: "relative",
                }}
              >
                <Img src={cardBoss.img} className={classes.imgCard} />
                <div className="img-content-pvePage">
                  <div className="img-content-pvePage-left">
                    <p>POWER : {cardBoss.Boss_Power}</p>
                    <p>DEFENCE : {cardBoss.Boss_Defence}</p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                </div>
                <Img
                  style={{
                    position: "absolute",
                    width: "24px",
                    bottom: 294,
                    left: 11,
                  }}
                  src={cardBoss.Boss_Rare}
                  className="numberCard3-pvePage-right"
                />
              </div>
              {cardBoss == "" ? (
                <div className={classes.containBtn}>
                  <GreyButton
                    title="WIN"
                    onClick={() => {
                      if (cardInfo !== "") {
                        betWinCard();
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <div className={classes.containTxGem}>
        <div className={classes.titleGem}>YOUR GEM</div>
      </div>

      <div className={classes.frameBtn}>
        <img src={frame} className={classes.frameImg} />
        {gem !== 0 ? (
          <div
            onClick={() => {
              if (address) {
                setOnPopupGem(true);
              } else {
                setOpenModal(true);
              }
            }}
          >
            <img src={gem.img} className={classes.gem} />
          </div>
        ) : (
          <div
            className={classes.titleImg}
            onClick={() => {
              if (address) {
                setOnPopupGem(true);
              } else {
                setOpenModal(true);
              }
            }}
          >
            Choose
          </div>
        )}
      </div>
      <div style={{ marginLeft: 54, marginBottom: 54 }}>
        <WoodButton title="COUNTDOWN" />
      </div>
      <div className={classes.containCard2}>
        {YourCard.map((item, index) => (
          <LazyLoad>
            <CardComponent item={item} index={index} />
          </LazyLoad>
        ))}
      </div>
      <div>
        <Footer />
      </div>
      {!allowance ? <ModalApprove /> : null}
      {onPopupCard ? (
        <PopupCard
          selectCard={onClickSelectCard}
          setOpen={setOnPopupCard}
          type="PVE"
        />
      ) : null}
      {openModal ? (
        <ModalConnect type="another" open={openModal} setOpen={setOpenModal} />
      ) : null}
      {openResult ? (
        <ModalResult
          onClick={setOpenResult}
          onClose={setOpenResult}
          result={betwin}
          reset={() => {
            setCardBoss("");
            setCardInfo("");
            setBetwin("");
            setGem(0);
          }}
        />
      ) : null}
      {onPopupGem && (
        <PopupGem
          setOpen={setOnPopupGem}
          selectGem={onSelectGem}
          open={onPopupGem}
        />
      )}
      {refresh ? <LoadingPage /> : null}
      {error && <ModalError onClick={setError} errors={noti}/>}
    </div>
  );
};
