import moment from "moment";
import { useEffect, useState } from "react";

export const CountDownTimer = ({ eventTime, type, refresh}) => {
  const calculateTimeLeft = () => {
    let currentTime = Math.trunc(Date.now() / 1000).toString();
    let duration = moment.duration(eventTime - currentTime, "second");

    let interval = 1000;
    if (duration.asSeconds() <= 0) {
      clearInterval(interval);
    }
    if(duration > 0){
      duration = moment.duration(duration.asSeconds() - 1, "second");
      if(type === "STAKING"){
        if(duration === 0){
            refresh(true)
        }
      }
    }
    
    return (
      duration.hours() +
      ":" +
      duration.minutes() +
      ":" +
      duration.seconds() +
      ""
    );
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  return (
    <div style={{ color: "white", textShadow: "2px 2px 10px #0099ff" }}>
      {timeLeft}
    </div>
  );
};
