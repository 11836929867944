import { Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import { Link } from "react-router-dom";
import "./styleFooter.css"
import clsx from "clsx";
import youtube from "../../assets/IconFooter/02-02.png"
import nothing from "../../assets/IconFooter/02-03.png"
import telegram from "../../assets/IconFooter/02-06.png"
import twitter from "../../assets/IconFooter/02-07.png"
import discord from "../../assets/IconFooter/02-08.png"
import logo from "../../assets/logo.png"
const DataLink = [
  {
    name: "GEM",
    link: "/",
    img: "https://www.location-moto-car-phuket.com/wp-content/uploads/2014/07/ShopIcon.png",
  },
  {
    name: "CARD",
    link: "openpage",
    img: "https://i.ibb.co/HTMfMRF/klipartz-com.png",
  },
  {
    name: "PVE",
    link: "pvepage",
    img: "https://i.ibb.co/RQVRkQR/Pngtree-vector-coins-icon-3788228.png",
  },
  {
    name: "PVP",
    link: "pvppage",
    img: "https://i.ibb.co/rvXWGrq/Pngtree-icon-card-game-vector-5538752.png",
  },
  {
    name: "STAKING",
    link: "stakingpage",
    img: "https://cdn-icons-png.flaticon.com/128/3522/3522092.png",
  },
  {
    name: "ADVENTURE",
    link: "adventure",
    img: "https://cdn-icons-png.flaticon.com/128/3522/3522092.png",
  },
  {
    name: "MARKET",
    link: "market",
    img: "https://cdn-icons-png.flaticon.com/128/3522/3522092.png",
  },
  {
    name: "YOUR REWARD",
    link: "yourrewardpage",
    img: "https://cdn-icons-png.flaticon.com/128/3522/3522092.png",
  },
  {
    name: "MY VAULT",
    link: "cardpage",
    img: "https://cdn-icons-png.flaticon.com/128/3522/3522092.png",
  },
];
const DataIcon = [
  {
    id: "Discord",
    icon: discord,
    link:"https://t.me/EmpireWarriorsgroup"
  },
  {
    id: "Youtube",
    icon: youtube,
    link:"https://www.youtube.com/channel/UCU2fPopYXEgC8VDJL8OstiQ"
  },
  {
    id: "Twitter",
    icon: twitter,
    link:"#"
  },
  {
    id: "Telegram",
    icon: telegram,
    link:"https://t.me/EmpireWarriorsChannel"
  },
  {
    id: "Reddit",
    icon: nothing,
    link:"https://www.reddit.com/user/empirewarriors"
  },
];
export const Footer = () => {
  const classes = useStyles();
  const [column, setColumn] = useState(false);
  const showColum = () => {
    if (window.innerWidth <= 960) {
      setColumn(false);
    } else {
      setColumn(true);
    }
  };
  window.addEventListener("resize", showColum);
  useEffect(() => {
    showColum();
  }, []);
  return (
    <div className={clsx("mainFooter", classes.mainFooter)}>
        {/** */}
        <div className={classes.contain1}>
          <div className={classes.containLink}>
            {/* {
                            DataLink.map((tab, index) => <Link key={index.toString()} to={tab.link} className={classes.link}>
                                <div className={classes.tab}>{tab.name}</div>
                            </Link>)
                        } */}
            {/* <Link className={classes.link}> */}
              <div className="linkPage">
                <a href="#" style={{textDecoration:"none"}}>
                  <div className={classes.tab}>HOMEPAGE</div>
                </a>
              </div>
            {/* </Link> */}
          </div>
          {/* <div className="logoImgFooter">
            <img src={logo} alt="" className="logoFooter"/>
          </div> */}
          {/** */}
          <div className={classes.containIcon}>
            {DataIcon.map((item, index) => (
              <Tooltip title={item.id} key={index.toString()}>
                <a href={item.link}>
                   <img src={item.icon} className={classes.iconImg} />
                </a>
               
              </Tooltip>
            ))}
          </div>
          {/** */}
        </div>
        {/** */}

        <div className={classes.contain1}>
          <div className={classes.containLinkBottom2}>
            ©2022 SoulSword, all rights reserved.
          </div>
          <div className={classes.grow} />

          <div className={classes.containLinkBottom}>
            <Link className={classes.title1}>
              Terms & Conditions ❘ Privacy Policy
            </Link>
          </div>
        </div>
    </div>
  );
};
