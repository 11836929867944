import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  imgCard: {
    width: "100%",
    height: "100%",
    maxWidth: "200px",
    borderRadius: 10,
  },
  gem:{
    width:"100%",
    height:"auto",
    maxWidth:60,
    position:'absolute',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  gem2:{
    width:"100%",
    height:"auto",
    maxWidth:40,
    position:'absolute',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  containCard: {
    minHeight: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop:150
  },
  title: {
    color: "white",
  },
  emptyImg: {
    border: "3px dashed white",
    width: 200,
    height: 325,
    borderRadius: 10,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    color: "white",
    "&:hover": {
      transition: "all 0.2s ease-in-out",
      textShadow: "2px 2px 8px #fff",
      color: "white",
      cursor: "pointer",
    },
  },
  containBtn: {
    marginTop: 32,
  },
  frame: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  frameBtn: {
    position: "relative",
    textAlign: "center",
    color: "white",
    margin:20,
    "&:hover": {
        transition: "all 0.2s ease-in-out",
        textShadow: "2px 2px 8px #fff",
        color: "white",
      },
  },
  frameImg: {
    width: "100%",
    maxWidth: 200,
    height: "auto",
  },
  titleImg: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  containBtn2:{
      marginTop:32,
      display:"flex",
      flexDirection:'row'
  },
  
}));
