import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  body: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "70px 30px 50px 30px",
  },
  img: {
    height: "auto",
    maxWidth: "100px",
  },
  btnGame: {
    background: "linear-gradient(to top,#1e342b,#2a4e4b,#005337)",
    padding: "24px",
    fontSize: "2.5vmin",
    color: "white",
    borderRadius: 50,
    transition: "all 0.2s ease-in-out",

    "&:hover": {
      transition: "all 0.2s ease-in-out",
      textShadow: "2px 2px 8px #fff",
    },
  },
  containBtn2:{
    display: "flex",
    justifyContent: "center",
    margin:64
  },
  imgCard: {
    width: "100%",
    height: "100%",
    maxWidth: 250,
    borderRadius: 10,
  },
  btnWin: {
    background:
      "linear-gradient(to top,#6a1111,#711414,#801a1a,#a72929,#cd3838,#fa4a4a)",
    padding: "8px 64px 8px 64px",
    fontSize: "1.5vmin",
    color: "white",
    display: "flex",
    fontFamily: "Jersey M54",
    transition: "all 0.2s ease-in-out",
    border: "none",
    borderRadius: 6,
    "&:hover": {
      transition: "all 0.2s ease-in-out",
      textShadow: "2px 2px 8px #fff",
    },
  },
  containBtn: {
    display: "flex",
    justifyContent: "center",
    margin:32
  },
  containCard: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  VSview: {
    marginLeft: 32,
    marginRight: 32,
    fontSize: "5vmin",
    color: "#fff",
  },
  grow: {
    flexGrow: 1,
  },
  imgGem: {
    position: "absolute",
    width: "32px",
    height: "32px",
    marginLeft: "84px",
    marginTop: "189px",
    zIndex: 1,
  },
  imgGem2: {
    position: "absolute",
    width: "18px",
    bottom: 294,
    left: 14,
  },
  imgGem3: {
    width: "14px",
    height: "14px",
  },
  containRare: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  theme: {
  
  },
  containTheme: {
    display: "flex",
    alignSelf: "flex-start",
    marginLeft: 54,
    marginBottom: 32,
    position: "relative",
  },
  imgTheme: {
    width: "100%",
    maxWidth: 150,
    height: "auto",
  },
}));
