import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    backgroundNavbar: {
        background:"none",
        zIndex:20,
        boxShadow:"none"
    },
    backgroundNavbar2: {
        background:"rgba(0,0,0,0.7)",
        zIndex:20,
        transition: 'all 0.2s ease-in-out',
    },
    tab: {
        width:60,
        height:60
    },
    grow: {
        flexGrow: 1
    },
    tabName: {
        color: '#000',
        textTransform: 'uppercase',
        fontSize: '1.1vw',
        fontFamily: 'Open sans',
        fontWeight: 800,
        position: 'absolute',
        backgroundColor: 'white',
        padding: '4px 8px 4px 8px',
        boxShadow: '1px 3px #888888',
        borderRadius: 10,
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    tabs: {
        textDecoration: 'none',
        color: 'white',
        cursor: 'pointer',
    },
    menuIcon: {
        color: '#212121',
        [theme.breakpoints.down('md')]: {
            color: '#e5e5e5',
        },
    },
    list: {
        width: '250px',
        height: '100%',
        background: "black",
    },
    fullList: {
        width: 'auto',
    },
    row1: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        alignItems: 'center',
    },
    menuMobile: {
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    home: {
        textDecoration: 'none',
        cursor: 'pointer',
    },
    main: {
        flexGrow: 1
    },
    menuTitleMobile: {
        paddingBottom:8,
        borderRadius:6,
        fontWeight: 800,
        verticalAlign: 'baseline',
        textAlign: 'center',
        fontSize: 17,
        textTransform: 'uppercase',
        color: 'white',
        textDecoration:"none",
       
    },
    sectionDesktop: {
        // marginTop: 50,
        [theme.breakpoints.down('md')]: {
            marginLeft: 5,
            marginRight: 5,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    nav: {
        paddingRight:32,
        fontSize:"2.5vmin",
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
            transition: 'all 0.2s ease-in-out',
            textShadow:"2px 2px 8px #fff",
        },
    },
    
}))
