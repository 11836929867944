import React, { useEffect, useState } from "react";
import { Footer, WoodButton, GreyButton } from "../../components";
import { useStyles } from "./style";
import {
  DataClass,
  DataRare,
  useStoreApi,
  myEffect,
} from "../../services/index";
import clsx from "clsx";
import ModalConnect from "../../components/ModalConnect/ModalConnect";
import { PopupNotify, FlyEffect } from "../../components/index";
import { DataImage } from "../../services";
import "../../styles/globalStyle.css";
import "./style.css";
import "../../styles/rainbowEffect.css";
import "../../styles/cardEffect.css";
import Img from "react-cool-img";
import { DataGemFrame } from "../../services/index";
import LazyLoad from "react-lazyload";
import { getAllCARD, getAllGEM, getAllItem } from "../../services";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import ModalApprove from "../../components/ModalApprove/ModalApprove";
const GemComponent = React.memo(({ item, index, onClick}) => {
  //console.log("alo kkkkk",item)
  const classes = useStyles();
  const [img, setImg] = useState("");
  const queryImg = () => {
    switch (Number(item.gem)) {
      case 0:
        setImg(DataGemFrame[0].img);
        break;
      case 1:
        setImg(DataGemFrame[2].img);
        break;
      case 2:
        setImg(DataGemFrame[1].img);
        break;
      case 3:
        setImg(DataGemFrame[3].img);
        break;
      case 4:
        setImg(DataGemFrame[4].img);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    queryImg();
  }, []);
  return (
    <div className="gemList" key={index}>
      <img className="gemList-img" src={img} />
      <div className="gemList-content">
        <h3 className="gemList-content-title">
          Bonus {item.idImg === "onyx" ? "Reward" : "Exp"}{" "}
        </h3>
        <h3 className="gemList-content-subtitle">
          Between {item.lowest}% And {item.highest}%
        </h3>
        <div className={classes.containBtn2}>
          <GreyButton title="SELL" onClick={onClick} />
        </div>
      </div>
    </div>
  );
});
const ITEMComponent = React.memo(({ item, index, onClick}) => {
  const classes = useStyles();
  const [img, setImg] = useState("");
  //console.log('kkkk',item)
  const queryImg = () => {
     DataGemFrame.map(data => {
      if(item.idImg === data.id ){
        setImg(data.img)
      }
     })
  };
  useEffect(() => {
    queryImg();
  }, []);
  return (
    <div className="gemList margin" key={index}>
      <img className="gemList2-img" src={img} />
      <div className="gemList-content">
        <div className="btn-Item" style={{
          marginTop:100

        }}>
          <GreyButton title="SELL" onClick={onClick} />
        </div>
      </div>
    </div>
  );
});
const CardComponent = React.memo(({ item, index, onClick }) => {
  const classes = useStyles();
  const [imgCard, setImgCard] = useState("");
  const [imgClass, setImgClass] = useState("");
  const [imgCounter, setImgCounter] = useState("");
  const [imgRare, setImgRare] = useState("");
  const [effect, setEffect] = useState("");
  const checkCard = () => {
    DataImage.map((data) => {
      if (data.id === Number(item.idImg)) {
        setImgCard(data.img);
      }
    });
  };
  const checkClass = () => {
    DataClass.map((data) => {
      if (data.id === Number(item.class)) {
        setImgClass(data.img);
      }
      if (data.name === item.counter) {
        setImgCounter(data.img);
      }
    });
  };
  const checkRare = () => {
    DataRare.map((data) => {
      if (data.id === Number(item.rare)) {
        //console.log(data.id);
        setImgRare(data.img);
      }
    });
  };
  const randomEffect = () => {
    let effectcard = myEffect[Math.floor(Math.random() * myEffect.length)];
    return effectcard.name;
  };
  const checkEffect = () => {
    if (Number(item.rare) === 0 || Number(item.rare) === 1) {
      setEffect("card0");
    }
    if (Number(item.rare) === 2 || Number(item.rare) === 3) {
      let effectCard = randomEffect();
      setEffect(effectCard);
    }
    if (Number(item.rare) === 4) {
      setEffect("rainbow");
    }
  };

  useEffect(() => {
    checkCard();
    checkClass();
    checkRare();
    checkEffect();
  }, []);
  return (
    <div style={{ padding: 32 }}>
      <div
        key={index}
        //className={`${effect}`}
      >
        <div
          style={{
            position: "relative",
            color: "white",
            height: 333,
          }}
          className={effect}
        >
          <Img
            className="gemPurple-stakingPage"
            src={imgClass}
            style={{
              position: "absolute",
              width: 32,
              height: 32,
              marginLeft: 89,
              marginTop: 192,
              zIndex: 1,
            }}
          />
          <Img src={imgCard} className={clsx(classes.imgCard)} />
          <Img
            style={{
              position: "absolute",
              width: "24px",
              top: 12,
              left: 12,
            }}
            src={imgRare}
            className="numberCard3-stakingPage"
          />

          <div className="img-content-cardPage">
            <div className="img-content-stakingPage-left">
              <p>POWER : {item.power}</p>
              <p>DEFENCE : {item.defence}</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div>COUNTER : </div>
                {imgCounter !== "" && (
                  <Img
                    className="gemPurple-couter-cardPage"
                    src={imgCounter}
                    style={{
                      width: "14px",
                      height: "14px",
                    }}
                  />
                )}
              </div>
              <p>BONUS REWARD : {item.reward}%</p>
            </div>
            <div className="img-content-stakingPage-right">
              <p>LEVEL : {item.level}</p>
              <p>EXP : {item.exp}</p>
              <p>TURN : {item.turn}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.containBtn}>
        <GreyButton title="SELL" onClick={onClick} />
      </div>
    </div>
  );
});
export const CardsPage05 = () => {
  const classes = useStyles();
  const { address, allowance } = useStoreApi();
  const [openCard, setOpenCard] = useState(false);

  const [onMouse, setOnMouse] = useState([]);
  const checkConnectWallet = () => {
    setOpenCard(true);
  };
  const [effect, setEffect] = useState("");
  const randomEffect = () => {
    let effectcard = myEffect[Math.floor(Math.random() * myEffect.length)];
    setEffect(effectcard.name);
  };
  //Get Card
  const [loading, setIsLoading] = useState(false);
  const [YourCard, setYourCard] = useState([]);
  const getDataCard = async () => {
    if (address) {
      setIsLoading(true);

      let snapshot = await getAllCARD(address).then((res) => res);
      let resultData = snapshot.filter((item) => Number(item.bornTime) !== 0);
      setYourCard(resultData);
      setIsLoading(false);
    }
  };
  //console.log(YourCard)
  //Get Gem
  const [YourGem, setYourGem] = useState([]);
  const getDataGem = async () => {
    if (address) {
      setIsLoading(true);

      let snapshot = await getAllGEM(address).then((res) => res);

      //console.log("al9", snapshot);
      let resultData = snapshot.filter((item) => Number(item.bornTime) !== 0);
      //console.log("dsdf",resultData)
      setYourGem(resultData);
      setIsLoading(false);
    }
  };
  const [ITEM, setItem] = useState([]);
  const getDataItem = async () => {
    if (address) {
      setIsLoading(true);

      let snapshot = await getAllItem(address).then((res) => res);

      //console.log("al9", snapshot);
      let resultData = snapshot.filter((item) => Number(item.bornTime) !== 0);
      //console.log("dsdf", resultData)
      setItem(resultData);
      setIsLoading(false);
    }
  };
  const [openModal, setOpenModal] = useState();
  // const [approve, setApprove] = useState(false);
  const checkAccount = () => {
    if (address === null) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };
  const checkLogout = () => {
    if (!address) {
      setYourCard([]);
      setYourGem([]);
    }
  };
  useEffect(() => {
    randomEffect();
  }, [onMouse]);
  useEffect(() => {
    getDataCard();
    getDataItem();
    getDataGem();
    checkAccount();
    checkLogout();
  }, [address]);
 
  return (
    <>
      <div className="cover font-face-gm">
        <FlyEffect />
        <div className={classes.body}>
          {/**GEM*/}

          {/** */}
          {/**Card */}

          {/**Gem */}
          <div className={classes.containTheme}>
            {/* <img src={theme} className={classes.imgTheme} />
            <div className={classes.theme}>GEM</div> */}
            <WoodButton title="GEM" />
          </div>

          <div className="gemsList">
            {YourGem.length == 0 ? (
              <div style={{ color: "#fff" }}>You do not have any gem</div>
            ) : (
              YourGem.map((item, index) => (
                <GemComponent
                  item={item}
                  index={index}
                  onClick={() => checkConnectWallet()}
                />
              ))
            )}
          </div>
          <div className={classes.containTheme}>
            {/* <img src={theme} className={classes.imgTheme} />
            <div className={classes.theme}>GEM</div> */}
            <WoodButton title="ITEM" />
          </div>

          <div className="gemsList">
            {ITEM.length == 0 ? (
              <div style={{ color: "#fff" }}>You do not have any item</div>
            ) : (
              ITEM.map((item, index) => (
                <ITEMComponent
                  item={item}
                  index={index}
                  onClick={() => checkConnectWallet()}
                />
              ))
            )}
          </div>
          {/**Card */}
          <div className={classes.containTheme}>
            {/* <img src={theme} className={classes.imgTheme} />
            <div className={classes.theme}>Card</div> */}
            <WoodButton title="CARD" />
          </div>
          <div className={classes.containCard}>
            {/**1 */}
            {YourCard.length === 0 ? (
              <div style={{ color: "#fff" }}>You do not have any card</div>
            ) : (
              YourCard.map((item, index) => (
                <LazyLoad>
                  <CardComponent
                    item={item}
                    index={index}
                    onClick={() => checkConnectWallet()}
                  />
                </LazyLoad>
              ))
            )}
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </div>
      {!allowance ? <ModalApprove /> : null}
      {loading ? <LoadingPage /> : null}
      {openModal ? (
        <ModalConnect type="another" open={openModal} setOpen={setOpenModal} />
      ) : null}
      {openCard && (
        <PopupNotify
          title="MARKET COMING SOON"
          //img={popupCard}
          onClick={setOpenCard}
          //setOpen={setOpenModal}
        />
      )}
    </>
  );
};
