import React, {useState} from "react";
import "./modalApprove.css";
import confirmImg from "../../assets/confirm.png";
import { fetchAccountAllowance, useStoreApi, approve } from "../../services";
export default function ModalApprove() {
  const { address, setAllowance, setAddress } = useStoreApi();
  const [loading, setLoading] = useState(false);
  const onApprove = async () => {
    setLoading(true);
    await approve(address).then(async (res) => {
      
      if (res) {
        let check = await fetchAccountAllowance(address);
        if (check > 1000000000) {
          setAllowance(true);
        } else {
          setAllowance(false);
        }
        setLoading(false);
      }
    });
  };

  return (
    <div className="containModal">
      <div className="broad-approve">
        <div className="broad-approve-top">
          <img src={confirmImg} alt="" className="confirm-img" />
          <div className="approve-content">
            Please approve before playing game
          </div>
        </div>
        {loading ? null : (
          <div className="broad-approve-bottom">
            <button
              className="broad-approve-btn btn--approve"
              onClick={() => onApprove()}
            >
              APPROVE
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
