import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  img: {
    height: "auto",
    maxWidth: "100px",
  },
  titleImg: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color:'#fff'
  },
  selectGem: {
    width: "100%",
    maxWidth: 150,
    height: "auto",
  },
  themerandom:{
    position: "relative",
    color: "white",
    height: 300,
  },
  themerandom2:{
    position: "relative",
    color: "white",
  },
  btnGame: {
    background: "linear-gradient(to top,#1e342b,#2a4e4b,#005337)",
    padding: "24px",
    fontSize: "2.5vmin",
    color: "white",
    borderRadius: 50,
    transition: "all 0.2s ease-in-out",

    "&:hover": {
      transition: "all 0.2s ease-in-out",
      textShadow: "2px 2px 8px #fff",
    },
  },
  imgCard: {
    width: "100%",
    height: "auto",
    maxWidth:200,
    borderRadius: 10,
  },
  btnWin: {
    background:
      "linear-gradient(to top,#6a1111,#711414,#801a1a,#a72929,#cd3838,#fa4a4a)",
    padding: "8px 64px 8px 64px",
    fontSize: "1.5vmin",
    color: "white",
    display: "flex",
    fontFamily: "Jersey M54",
    transition: "all 0.2s ease-in-out",
    border: "none",
    borderRadius: 6,
    "&:hover": {
      transition: "all 0.2s ease-in-out",
      textShadow: "2px 2px 8px #fff",
    },
  },
  containBtn: {
    display: "flex",
    justifyContent: "center",
    marginTop: 16,
  },
  containCard: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  VSview: {
    marginLeft: 32,
    marginRight: 32,
    fontSize: "5vmin",
    color: "#fff",
  },
  grow: {
    flexGrow: 1,
  },
  containContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    //alignItems: "center",
    textAlign:"center"
  },
  containTheme: {
    textAlign: "center",
    background: "rgba(0,0,0,0.5)",
    transform: "translate(-50%, -50%)",
    padding: "8px 16px",
    borderRadius: 6,
    
  },
  stakingTime: {
    fontSize:"15px",
    marginLeft: "-100px",
    width:'200px',
    marginBottom:'10px'
  },
  stakingReward: {
    fontSize:"14px",
    marginLeft: "-100px",
    width:'200px'
  },
  theme: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  containTheme2: {
    display: "flex",
    alignSelf: "flex-start",
    marginLeft: 54,
    marginBottom: 64,
    alignItems:'center'
  },
  imgTheme: {
    width: "100%",
    maxWidth: 150,
    height: "auto",
  },
  frameBtn: {
    position: "relative",
    textAlign: "center",
    color: "white",
    margin:20,
    "&:hover": {
        transition: "all 0.2s ease-in-out",
        textShadow: "2px 2px 8px #fff",
        color: "white",
      },
  },
  frameImg: {
    width: "100%",
    maxWidth: 200,
    height: "auto",
  },
  gem:{
    width:"100%",
    height:"auto",
    maxWidth:100,
    position:'absolute',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  notiCT:{
    position: "absolute",
    top: "50%",
    left: "50%",
    textAlign:'center',
    transform: "translate(-50%, -50%)",
    backgroundColor:'rgba(0,0,0,0.5)',
    width:"100%",
    paddingTop:20,
    paddingBottom:20
  }
}));
