import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
   mainFooter:{
       [theme.breakpoints.down('sm')]: {
        paddingLeft:0,
        paddingRight:0,
    },
   },
  link:{
      marginBottom:16,
      color:'#ffeda0',
      textDecoration:'none',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
    },
  },
  titleBottom:{
      color:'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        display:'block'
    },
  },
  title2:{
      color:'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        display:'block'
    },
  },
  title1:{
      color:'white',
      textDecoration:'none',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        display:'block'
    },
  },
  containLink:{
    display:'flex',
    width:'330px',
    justifyContent:'space-between',
    [theme.breakpoints.down('sm')]: {
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      marginTop:16,
      marginBottom:10
  },
  },
  containIcon:{
    display:'flex',
    alignItems:'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent:'center',
      display:'flex',
      marginTop:10
  },
  },
  iconImg:{
    width:'3vw',
    height:'3vw',
    cursor:'pointer'
  },
  containLinkBottom:{
    marginTop:32,
    [theme.breakpoints.down('sm')]: {
      justifyContent:'center',
      display:'flex',
  },
  },
  containLinkBottom2:{
    marginTop:32,
    color:'#fff',
    [theme.breakpoints.down('sm')]: {
      justifyContent:'center',
      display:'flex',
  },
  },
  contain1:{
    flexWrap:"wrap",
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection:'column',
      display:'flex',
  },
  },
  tab:{
    textDecoration:'none',
    letterSpacing:'2px'
  },
  grow:{
    flexGrow:1,
    [theme.breakpoints.down('sm')]: {
    flexGrow:0
  },
  }
}))
