import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import {
  Footer,
  FlyEffect,
  PopupCard,
  GreyButton,
  WoodButton,
  PopupNotify,
  ModalError,
  CountDownTimer,
  PopupGem,
} from "../../components";
import LazyLoad from "react-lazyload";
import { useStyles } from "./style";
import "../../styles/BorderEffect.css";
import "../../styles/globalStyle.css";
import "../../styles/rainbowEffect.css";
import "./style-PVP-Page.scss";
import "./style.css";
import Img from "react-cool-img";
import {
  DataImage,
  DataBoss,
  getAllCARD,
  getRandomCard,
  GetCARD,
  Combat,
  myEffect,
  useStoreApi,
  DataClass,
  DataRare,
  DataGem
} from "../../services";
import "../../styles/ButtonSpinner.css";
import frame from "../../assets/login board + buttons/LGBT-02.png";

import ModalApprove from "../../components/ModalApprove/ModalApprove";
import bossEmpty from "../../assets/pvp.png";
import ModalConnect from "../../components/ModalConnect/ModalConnect";
import ModalResult from "../../components/ModalResult/ModalResult";
import vsImg from "../../assets/vs-01.png";
import LoadingPage from "../../components/LoadingPage/LoadingPage";

const CardComponent = React.memo(({ item, index }) => {
  const classes = useStyles();
  const [imgCard, setImgCard] = useState("");
  const [imgClass, setImgClass] = useState("");
  const [imgCounter, setImgCounter] = useState("");
  const [imgRare, setImgRare] = useState("");
  const [effect, setEffect] = useState("");
  const [time, setTime] = useState("");
  const checkCard = () => {
    DataImage.map((data) => {
      if (data.id === Number(item.idImg)) {
        setImgCard(data.img);
      }
    });
  };

  const checkClass = () => {
    DataClass.map((data) => {
      if (data.id === Number(item.class)) {
        setImgClass(data.img);
      }
      if (data.name === item.counter) {
        setImgCounter(data.img);
      }
    });
  };
  const checkRare = () => {
    DataRare.map((data) => {
      if (data.id === Number(item.rare)) {
        //console.log(data.id);
        setImgRare(data.img);
      }
    });
  };
  const randomEffect = () => {
    let effectcard = myEffect[Math.floor(Math.random() * myEffect.length)];
    return effectcard.name;
  };
  const checkEffect = () => {
    if (Number(item.rare) === 0 || Number(item.rare) === 1) {
      setEffect("card0");
    }
    if (Number(item.rare) === 2 || Number(item.rare) === 3) {
      let effectCard = randomEffect();
      setEffect(effectCard);
    }
    if (Number(item.rare) === 4) {
      setEffect("rainbow");
    }
  };
  const [notiCard, setNotiCard] = useState("");
  const getTimeCountDown = () => {
    let countDown = 0;
    let turn = [3, 4, 5, 7, 9];
    turn.map((data, index) => {
      if (Number(item.turn) < data && Number(item.rare) === index) {
        countDown = Number(item.lastplayedTime) + 3600;
        setNotiCard("Waiting for next time");
      }

      if (Number(item.rare) === index && Number(item.turn) === data) {
        let date = new Date(Number(item.lastplayedTime) * 1000).setUTCHours(
          0,
          0,
          0,
          0
        );
        let today = new Date().setUTCHours(0, 0, 0, 0);
        setNotiCard("Waiting for next day");
        if (date === today) {
          let tomorrow = new Date(today).getTime() + 24 * 60 * 60 * 1000;
          countDown = Number(tomorrow) / 1000;
        }
      }
    });

    setTime(Number(countDown));
  };

  useEffect(() => {
    checkCard();
    checkClass();
    checkRare();
    checkEffect();
    getTimeCountDown();
  }, []);
  return (
    <div style={{ flexDirection: "column", margin: 4 }} key={index}>
      <div style={{ padding: 32 }}>
        <div
          key={index}
          //className={`${effect}`}
        >
          <div
            style={{
              position: "relative",
              color: "white",
              height: 333,
            }}
            className={effect}
          >
            <Img
              className="gemPurple-stakingPage"
              src={imgClass}
              style={{
                position: "absolute",
                width: 32,
                height: 32,
                marginLeft: 85,
                marginTop: 192,
                zIndex: 1,
              }}
            />
            <Img src={imgCard} className={clsx(classes.imgCard)} />
            <Img
              style={{
                position: "absolute",
                width: "24px",
                top: 12,
                left: 11,
              }}
              src={imgRare}
              className="numberCard3-stakingPage"
            />

            <div className="img-content-cardPage">
              <div className="img-content-stakingPage-left">
                <p>POWER : {item.power}</p>
                <p>DEFENCE : {item.defence}</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div>COUNTER : </div>
                  {imgCounter !== "" && (
                    <Img
                      className="gemPurple-couter-cardPage"
                      src={imgCounter}
                      style={{
                        width: "14px",
                        height: "14px",
                      }}
                    />
                  )}
                </div>
                <p>BONUS REWARD : {item.reward}</p>
              </div>
              <div className="img-content-stakingPage-right">
                <p>LEVEL : {item.level}</p>
                <p>EXP : {item.exp}</p>
                <p>TURN : {item.turn}</p>
              </div>
            </div>
            <div className={classes.notiCT}>
              <div> {notiCard} </div>
            </div>
          </div>
        </div>

        {/* <div className={classes.containBtn}>
        <GreyButton title="SELL" onClick={onClick} />
      </div> */}
      </div>
      <div style={{ margin: 16, textAlign: "center" }}>
        <CountDownTimer eventTime={time} />
      </div>
    </div>
  );
});
export const PVPPage07 = () => {
  const classes = useStyles();
  const [cardInfo, setCardInfo] = useState("");
  const [cardBoss, setCardBoss] = useState("");
  const [noti, setNoti] = useState(false);
  const [loading, setLoading] = useState(false);
  const [betwin, setBetwin] = useState("");
  const [onPopupGem, setOnPopupGem] = useState(false);
  const [gem, setGem] = useState(0);
  const onSelectGem = (item) => {
    //console.log('alo',item.gem)
    if (Number(item.gem) === 0) {
      let result = DataGem.filter((data) => data.name === item.idImg);
      let object = result[0]
      object.idGem = item.id
      //console.log('test',object)
      setGem(object);
    } else {
      setError("You can't choose this gem")
      setNoti(true);
    }
  };
  //console.log('fdsfsf',gem)
  const betWinCard = async () => {
    setLoading(true);
    
    try {
      await Combat(cardInfo.id, cardBoss.id, gem.id !== undefined ? gem.id : gem, address).then(async (res) => {
        if (res) {
          let bet;
          
          if (res.logs !== undefined) {
            bet = formatStringResult(res.logs[res.logs.length-1].data)
            // if (res.logs.length === 2) {
            //   //Lose not item
            //   bet = formatStringResult(res.logs[1].data);
            // }
            // if (res.logs.length === 3) {
            //   //win not item
            //   bet = formatStringResult(res.logs[2].data);
            // }
            // if (res.logs.length === 4) {
            //   //lose have item
            //   bet = formatStringResult(res.logs[3].data);
            // }
            // if (res.logs.length === 5) {
            //   //win have item
            //   bet = formatStringResult(res.logs[4].data);
            // }
            // if (res.logs.length === 6){
            //   bet = formatStringResult(res.logs[5].data);
            // }
            setBetwin(bet);
            if (bet) {
              setOpenResult(true);
            }
          }
          setLoading(false);
        }
      });
    } catch (err) {
      setNoti(true)
      setError('Fight is failed')
      setLoading(false);
    }
  };
  const turnRandomCard = async (id) => {
    let classData = [];
    let rareData = [];
    let img = [];
    let effect;
    let counter = [];
    let newCard;
    try {
      await GetCARD(id).then((res) => {
        //console.log(res);
        img = DataImage.filter((item) => item.id === Number(res.idImg));
        //console.log(img)
        classData = DataClass.filter((item) => item.id === Number(res.class));

        rareData = DataRare.filter((item) => item.id === Number(res.rare));
        //counter
        counter = DataClass.filter((item) => item.name === res.counter);
        ///effect rare
        switch (rareData[0].id) {
          case 0:
            effect = "card0";
            break;
          case 1:
            effect = "card0";
            break;
          case 2:
            effect = randomEffect();
            break;
          case 3:
            effect = randomEffect();
            break;
          case 4:
            effect = "rainbow";
            break;
          default:
            break;
        }
        newCard = {
          class: classData[0].img,
          id: res.id,
          img: img[0].img,
          power: res.power,
          defence: res.defence,
          counter: counter.length !== 0 ? counter[0].img : "",
          reward: res.reward,
          level: res.level,
          exp: res.exp,
          turn: res.turn,
          rare: rareData[0].img,
          effect,
        };
        setCardBoss(newCard);
      });
    } catch (err) {
      //console.log(err);
      setNoti(true)
      setError('Random card is failed')
    }
    //return newCard;
  };
  const findCard = async () => {
    setLoading(true);
    //setCardBoss("");
    try {
      //let idCard = randomCardnBoss();
      await getRandomCard(cardInfo.id).then(async (res) => {
        turnRandomCard(res);
      });
      setLoading(false);
    } catch (err) {
      //console.log(err);
      setLoading(false);
      setNoti(true)
      setError('Find card is failed')
    }
  };
  const formatStringResult = (Data) => {
    let WIN = parseInt(Data.substring(2, 66), 16);
    let REWARD = parseInt(Data.substring(66, 130), 16);
    let ITEM = parseInt(Data.substring(130, 194), 16);
    let bet = {
      WIN,
      REWARD,
      ITEM,
    };
    return bet;
  };

  //thẻ bài win anh trả ở đây

  const [openModal, setOpenModal] = useState(false);

  const { address, allowance } = useStoreApi();
  const checkLogout = () => {
    if (!address) {
      setCardBoss("");
      setBetwin("");
      setCardInfo("");
      setYourCard([]);
    }
  };
  const [onPopupCard, setOnPopupCard] = useState(false);
  const onClickSelectCard = (card) => {
    setCardBoss("");
    if (!loading) {
      let imgCard;
      let rare;
      let effect;
      let classImg;
      let counter;
      setOnPopupCard(false);
      DataImage.map((data) => {
        if (data.id === Number(card.idImg)) {
          imgCard = data.img;
        }
      });
      DataClass.map((data) => {
        if (data.id === Number(card.class)) {
          classImg = data.img;
        }
        if (data.name === card.counter) {
          counter = data.img;
        }
      });
      DataRare.map((data) => {
        if (data.id === Number(card.rare)) {
          rare = data.img;
        }
      });
      if (Number(card.rare) === 0 || Number(card.rare) === 1) {
        effect = "card0";
      }
      if (Number(card.rare) === 2 || Number(card.rare) === 3) {
        let effectCard = randomEffect();
        effect = effectCard;
      }
      if (Number(card.rare) === 4) {
        effect = "rainbow";
      }
      let newCard = {
        imgCard,
        rare,
        effect,
        classImg,
        counter,
        power: card.power,
        defence: card.defence,
        reward: card.reward,
        level: card.level,
        exp: card.exp,
        turn: card.turn,
        id: card.id,
      };

      setCardInfo(newCard);
    }
  };
  const randomEffect = () => {
    let effectcard = myEffect[Math.floor(Math.random() * myEffect.length)];
    return effectcard.name;
  };
  const [openResult, setOpenResult] = useState(false);
  const [YourCard, setYourCard] = useState([]);

  const getDataCard = async () => {
    try {
      let snapshot = await getAllCARD(address).then(async (res) => res);
      let checkTime = snapshot.filter((item) => Number(item.bornTime) > 0);
      let resultData = checkTime.filter((item) => conditionDisplay(item));

      setYourCard(resultData);
      //snapshot.map()
    } catch (err) {}
  };

  const conditionDisplay = (item) => {
    let newDate = false;
    let display;
    let turn = [3, 4, 5, 7, 9];

    turn.map((data, index) => {
      if (
        (Number(item.turn) < data &&
          Number(item.rare) === index &&
          Number(item.lastplayedTime) > Number(Date.now() / 1000) - 3600) ||
        (Number(item.rare) === index && Number(item.turn) === data)
      ) {
        let date = new Date(Number(item.lastplayedTime) * 1000).setUTCHours(
          0,
          0,
          0,
          0
        );
        let today = new Date().setUTCHours(0, 0, 0, 0);

        if (Number(today) > Number(date)) {
          display = false;
          //console.log(newDate)
        } else {
          display = true;
        }
      }
    });
    return display;
  };
  const [error, setError] = useState("");
  useEffect(() => {
    getDataCard();
    checkLogout();
  }, [address, openResult]);
  //console.log(cardInfo)
  const [time, setTime] = useState(false);

  const countTime = () => {
    setTime(true);
    findCard();
    setTimeout(() => {
      setTime(false);
    }, 20000);
  };
  return (
    <div className="cover font-face-gm">
      <FlyEffect />
      <div className="body-pvePage">
        <div className={classes.containCard}>
          {/**1 */}
          <div className="container-img">
            {cardInfo == "" ? (
              <div
                className={clsx("emptyImg", classes.emptyImg)}
                onClick={() => {
                  if (address) {
                    setOnPopupCard(true);
                  } else {
                    setOpenModal(true);
                  }
                }}
              >
                <div>Choose</div>
              </div>
            ) : (
              <div
                className={` ${cardInfo.effect}`}
                style={{
                  position: "relative",
                  // display: "flex",
                  // justifyContent: "center",
                  height: 333,
                }}
                onClick={() => {
                  if (address) {
                    if (!loading) {
                      setOnPopupCard(true);
                    }
                  } else {
                    setOpenModal(true);
                  }
                }}
              >
                <Img src={cardInfo.imgCard} className={classes.imgCard} />
                <Img
                  style={{
                    position: "absolute",
                    width: "24px",
                    bottom: 292,
                    left: 10,
                  }}
                  src={cardInfo.rare}
                  className="numberCard3-pvePage-left"
                />

                <Img
                  className="gemPurple-pvePage"
                  src={cardInfo.classImg}
                  style={{
                    position: "absolute",
                    width: "32px",
                    height: "32px",
                    bottom: 110,
                    left: 83,
                  }}
                />

                <div className="img-content-pvePage">
                  <div className="img-content-pvePage-left">
                    <p>POWER : {cardInfo.power}</p>
                    <p>DEFENCE : {cardInfo.defence}</p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <div>COUNTER : </div>
                      {cardInfo.counter !== undefined && (
                        <img
                          className="gemPurple-couter-pvePage-left"
                          src={cardInfo.counter}
                          style={{
                            width: "14px",
                            height: "14px",
                          }}
                        />
                      )}
                    </div>
                    <p>BONUS REWARD : {cardInfo.reward}</p>
                  </div>
                  <div className="img-content-pvePage-right">
                    <p>LEVEL : {cardInfo.level}</p>
                    <p>EXP : {cardInfo.exp}</p>
                    <p>TURN : {cardInfo.turn}</p>
                  </div>
                </div>
              </div>
            )}

            {!loading && betwin == "" ? (
              <div className={classes.containBtn}>
                <GreyButton
                  title={"CHOOSE"}
                  onClick={() => {
                    if (address) {
                      setOnPopupCard(true);
                    } else {
                      setOpenModal(true);
                    }
                  }}
                />
              </div>
            ) : null}
          </div>
          <div className={clsx("columnBtn", classes.columnBtn)}>
            <div className={classes.containBtn2}>
              <div className={classes.VSview}>
                <Img src={vsImg} alt="" className={classes.vsImg} />
              </div>

              {
                <div className={classes.containBtn}>
                  <GreyButton
                    title="FIGHT"
                    onClick={() => {
                      if (address) {
                        if (cardBoss !== "" && cardInfo !== "") {
                          betWinCard();
                        } else {
                          setNoti(true);
                          if (cardBoss == "") {
                            setError("Please find a card");
                          }
                          if (cardInfo == "") {
                            setError("Please choose your card");
                          }
                        }
                      } else {
                        setOpenModal(true);
                      }
                    }}
                  />
                </div>
              }
            </div>
          </div>
          {cardBoss == "" ? (
            <>
              <div>
                <div className="img-random flip-bossImg-front">
                  {loading ? (
                    <div className={classes.containLoading}>
                      <div>Waiting.....</div>
                    </div>
                  ) : (
                    <Img
                      src={bossEmpty}
                      className={clsx("bossEmpty", classes.imgCard)}
                    />
                  )}
                </div>
                {!loading ? (
                  <div className={classes.containBtn}>
                    <GreyButton
                      title="FIND"
                      onClick={() => {
                        if (address) {
                          if (cardInfo !== "") {
                            if (!time) {
                              countTime();
                            } else {
                              setNoti(true);
                              setError("Please wait few minutes");
                            }
                          } else {
                            setNoti(true);
                            setError("Please choose your card");
                          }
                        } else {
                          setOpenModal(true);
                        }
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <div>
              <div
                className={`img-random ${cardBoss.effect} flip-bossImg-back`}
                style={{
                  position: "relative",
                  height: 333,
                }}
              >
                <Img src={cardBoss.img} className={classes.imgCard} />
                <div className="img-content-pvePage">
                  <div className="img-content-pvePage-left">
                    <p>POWER : {cardBoss.power}</p>
                    <p>DEFENCE : {cardBoss.defence}</p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <div>COUNTER : </div>
                      {cardBoss.counter !== undefined && (
                        <img
                          className="gemPurple-couter-pvePage-left"
                          src={cardBoss.counter}
                          style={{
                            width: "14px",
                            height: "14px",
                          }}
                        />
                      )}
                    </div>
                    <p>BONUS REWARD : {cardInfo.reward}</p>
                  </div>
                  <div className="img-content-pvePage-right">
                    <p>LEVEL : {cardBoss.level}</p>
                    <p>EXP : {cardBoss.exp}</p>
                    <p>TURN : {cardBoss.turn}</p>
                  </div>
                </div>
                <Img
                  style={{
                    position: "absolute",
                    width: "24px",
                    bottom: 292,
                    left: 11,
                  }}
                  src={cardBoss.rare}
                  className="numberCard3-pvePage-right"
                />
                <Img
                  className="gemPurple-pvePage"
                  src={cardBoss.class}
                  style={{
                    position: "absolute",
                    width: "32px",
                    height: "32px",
                    bottom: 110,
                    left: 84,
                  }}
                />
              </div>
              {!loading && betwin == "" ? (
                <div className={classes.containBtn}>
                  <GreyButton
                    title="FIND"
                    onClick={() => {
                      if (!time) {
                        countTime();
                      } else {
                        setNoti(true);
                        setError("Please wait few minutes");
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <div className={classes.frameBtn}>YOUR GEM</div>
      <div className={classes.frameBtn}>
        <Img src={frame} className={classes.frameImg} />
        {gem !== 0 ? (
          <div
            onClick={() => {
              if (address) {
                setOnPopupGem(true);
              } else {
                setOpenModal(true);
              }
            }}
          >
            <img src={gem.img} className={classes.gem} />
          </div>
        ) : (
          <div
            className={classes.titleImg}
            onClick={() => {
              if (address) {
                setOnPopupGem(true);
              } else {
                setOpenModal(true);
              }
            }}
          >
            Choose
          </div>
        )}
      </div>
      <div style={{ marginLeft: 54, marginBottom: 54 }}>
        <WoodButton title="COUNTDOWN" />
      </div>
      <div className={classes.containCard2}>
        {YourCard.map((item, index) => (
          <LazyLoad>
            <CardComponent item={item} index={index} />
          </LazyLoad>
        ))}
      </div>
      {!allowance ? <ModalApprove /> : null}
      {onPopupCard ? (
        <PopupCard
          selectCard={onClickSelectCard}
          setOpen={setOnPopupCard}
          type="PVP"
          open={onPopupCard}
        />
      ) : null}
      {openModal ? (
        <ModalConnect type="another" open={openModal} setOpen={setOpenModal} />
      ) : null}
      {openResult ? (
        <ModalResult
          onClick={setOpenResult}
          onClose={setOpenResult}
          result={betwin}
          reset={() => {
            setCardBoss("");
            setCardInfo("");
            setBetwin("");
            setGem(0)
          }}
        />
      ) : null}

      {noti ? <ModalError onClick={setNoti} errors={error} /> : null}
      {loading && <LoadingPage />}

      {onPopupGem && (
        <PopupGem
          setOpen={setOnPopupGem}
          selectGem={onSelectGem}
          open={onPopupGem}
        />
      )}
      <div>
        <Footer />
      </div>
    </div>
  );
};
