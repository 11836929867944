import { useStore } from ".";

export const useStoreApi = () => {
    const { state, dispatch } = useStore();
    return {
      balance: state.balance,
      address: state.address,
      message: state.message,
      allowance:state.allowance,
      EWB:state.EWB,
      setAllowance: allowance => {
         dispatch({
           type:"SET-ALLOWANCE",
           allowance: allowance
         }) 
      },
      setEWB: EWB => {
        dispatch({
          type:"SET-EWB",
          EWB: EWB
        }) 
     },
      setAddress: newAddress => {
        dispatch({
          type: "NEW-ADDRESS",
          address: newAddress,
          message: "New address added successfully!"
        });
      },
      setBalance: newBalance => {
        dispatch({
          type: "SET-BALANCE",
          balance: newBalance
        });
      }
    };
  };