import React, { useState, useEffect } from "react";
import {
  Footer,
  GreyButton,
  ModalError,
  PopupCard,
  PopupGem,
  PopupUpgrade,
} from "../../components";
import ModalApprove from "../../components/ModalApprove/ModalApprove";
import ModalConnect from "../../components/ModalConnect/ModalConnect";
import "../../styles/globalStyle.css";
import { useStyles } from "./style";
import clsx from "clsx";
import "./style.css";
import "./style.scss";
import frame from "../../assets/login board + buttons/LGBT-02.png";
import Img from "react-cool-img";
import item1 from "../../assets/Spinel.svg";
import item2 from "../../assets/Garnet.svg";
import {
  DataImage,
  DataClass,
  DataRare,
  myEffect,
  useStoreApi,
  upgrade,
  AllowanceItem,
  approveItem,
  GetCARD,
} from "../../services";
import ModalDynamic from "../../components/ModalDynamic/ModalDynamic";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
export const UpgradePage02 = () => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const { address, allowance } = useStoreApi();
  const [onPopupCard, setOnPopupCard] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [cardInfo, setCardInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [onPopupGem, setOnPopupGem] = useState(false);
  const [gemInfo, setGemInfo] = useState("");
  const randomEffect = () => {
    let effectcard = myEffect[Math.floor(Math.random() * myEffect.length)];
    return effectcard.name;
  };
  const onClickSelectCard = (card) => {
    setRefresh(true);
    if (!loading) {
      let imgCard;
      let rare;
      let effect;
      let classImg;
      let counter;
      setOnPopupCard(false);
      DataImage.map((data) => {
        if (data.id === Number(card.idImg)) {
          imgCard = data.img;
        }
      });
      DataClass.map((data) => {
        if (data.id === Number(card.class)) {
          classImg = data.img;
        }
        if (data.name === card.counter) {
          counter = data.img;
        }
      });
      DataRare.map((data) => {
        if (data.id === Number(card.rare)) {
          rare = data.img;
        }
      });
      if (Number(card.rare) === 0 || Number(card.rare) === 1) {
        effect = "card0";
      }
      if (Number(card.rare) === 2 || Number(card.rare) === 3) {
        let effectCard = randomEffect();
        effect = effectCard;
      }
      if (Number(card.rare) === 4) {
        effect = "rainbow";
      }
      let newCard = {
        imgCard,
        rare,
        effect,
        classImg,
        counter,
        power: card.power,
        defence: card.defence,
        reward: card.reward,
        level: card.level,
        exp: card.exp,
        turn: card.turn,
        id: card.id,
      };

      setCardInfo(newCard);
      setRefresh(false);
    }
  };
  const [ITEM, setITEM] = useState("");
  const [spinel, setSpinel] = useState("");
  const [garnet, setGarnet] = useState("");
  const onSelectSpinel = (item) => {
    //console.log("hihi", item);
    setSpinel(item);
  };
  const onSelectGarnet = (item) => {
    //console.log("haha", item);
    setGarnet(item);
  };
  const checkLogout = () => {
    if (!address) {
      setCardInfo("");
      setGemInfo("");
    }
  };
  const [error, setError] = useState(false);
  const [getUpgrade, setGetUpgrade] = useState("");
  const [open, setOpen] = useState(false);
  const [stop,setStop] = useState(false)
  const onUpgrade = async () => {
    let classData = [];
    let rareData = [];
    let img = [];
    let effect;
    let counter = [];
    let newCard;
    if (spinel === "" || garnet === "" || cardInfo === "") {
      setError(true);
      setStatus("Not enough items");
    } else {
      setLoading(true);
      
      try {
        let snapshot = await upgrade(
          cardInfo.id,
          spinel.id,
          garnet.id,
          address
        ).then(async (res) => res);
        if (snapshot) {
          setOpen(true);
          // setTimeout(async () => {
            let card = await GetCARD(cardInfo.id).then(async (res) => res);
            if (card) {
              img = DataImage.filter((item) => item.id === Number(card.idImg));
              //console.log(img)

              classData = DataClass.filter(
                (item) => item.id === Number(card.class)
              );

              rareData = DataRare.filter(
                (item) => item.id === Number(card.rare)
              );
              //counter
              counter = DataClass.filter((item) => item.name === card.counter);
              ///effect rare
              switch (rareData[0].id) {
                case 0:
                  effect = "card0";
                  break;
                case 1:
                  effect = "card0";
                  break;
                case 2:
                  effect = randomEffect();
                  break;
                case 3:
                  effect = randomEffect();
                  break;
                case 4:
                  effect = "rainbow";
                  break;
                default:
                  break;
              }
              newCard = {
                class: classData[0].img,
                img: img[0].img,
                power: card.power,
                defence: card.defence,
                counter: counter.length !== 0 ? counter[0].img : "",
                reward: card.reward,
                level: card.level,
                exp: card.exp,
                turn: card.turn,
                rare: rareData[0].img,
                effect,
              };
              setGetUpgrade(newCard);
            }
          // }, 5000);
        }
      } catch (error) {
        setLoading(false);
        setError(true);
        setStatus("Upgrade item is failed");
        setOpen(false);
      }
      setLoading(false);
    }
  };
  const [checkApprove, setCheckApprove] = useState(false);
  const checkApproveItem = async () => {
    try {
      let snapshot = await AllowanceItem(address).then(async (res) => res);
      //console.log('dsfsd',snapshot)
      setCheckApprove(snapshot);
    } catch (error) {}
  };
  const [noti, setNoti] = useState(false);
  const [status, setStatus] = useState("");
  const [reload, setReload] = useState([]);
  const onApproveItem = async () => {
    try {
      setLoading(true);
      let snapshot = await approveItem(address).then(async (res) => res);
      if (snapshot) {
        setReload([]);
        setNoti(true);
        setStatus("Approve item successfully");
      } else {
        setNoti(true);
        setStatus("Approve item unsuccessfully");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //console.log('errr',error)
    }
  };
  useEffect(() => {
    checkLogout();
    checkApproveItem();
  }, [address, reload]);
  return (
    <div className="cover font-face-gm">
      <div className={classes.containCard}>
        {/** */}
        <div className={classes.frame}>
          {cardInfo == "" ? (
            <div
              className={clsx("emptyImg", classes.emptyImg)}
              onClick={() => {
                if (address) {
                  setOnPopupCard(true);
                } else {
                  setOpenModal(true);
                }
              }}
            >
              <div>Choose</div>
            </div>
          ) : (
            <div
              className={` ${cardInfo.effect}`}
              style={{
                position: "relative",
                // display: "flex",
                // justifyContent: "center",
                height: 333,
              }}
              onClick={() => {
                if (address) {
                  if (!loading) {
                    setOnPopupCard(true);
                  }
                } else {
                  setOpenModal(true);
                }
              }}
            >
              <img
                style={{
                  position: "relative",
                  // display: "flex",
                  // justifyContent: "center",
                  height: 333,
                }}
                onClick={() => {
                  if (address) {
                    if (!loading) {
                      setOnPopupCard(true);
                    }
                  } else {
                    setOpenModal(true);
                  }
                }}
              />
              <img src={cardInfo.imgCard} className={classes.imgCard} />
              <img
                style={{
                  position: "absolute",
                  width: "24px",
                  bottom: 292,
                  left: 10,
                }}
                src={cardInfo.rare}
                className="numberCard3-pvePage-left"
              />

              <img className="gemPurple-pvePage" src={cardInfo.classImg} />

              <div className="img-content-pvePage">
                <div className="img-content-pvePage-left">
                  <p>POWER : {cardInfo.power}</p>
                  <p>DEFENCE : {cardInfo.defence}</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div>COUNTER : </div>
                    {cardInfo.counter !== undefined && (
                      <img
                        className="gemPurple-couter-pvePage-left"
                        src={cardInfo.counter}
                        style={{
                          width: "14px",
                          height: "14px",
                        }}
                      />
                    )}
                  </div>
                  <p>BONUS REWARD : {cardInfo.reward}</p>
                </div>
                <div className="img-content-pvePage-right">
                  <p>LEVEL : {cardInfo.level}</p>
                  <p>EXP : {cardInfo.exp}</p>
                  <p>TURN : {cardInfo.turn}</p>
                </div>
              </div>
            </div>
          )}
          <div className={classes.containBtn}>
            <GreyButton
              title={checkApprove ? "UPGRADE" : "APPROVE"}
              onClick={() => {
                if (address) {
                  if (checkApprove) {
                    onUpgrade();
                  } else {
                    onApproveItem();
                  }
                } else {
                  setOpenModal(true);
                }
              }}
            />
          </div>
        </div>
        {/**Button */}
        <div className={classes.containBtn2}>
          <div className={classes.frameBtn}>
            <Img src={frame} className={classes.frameImg} />
            {spinel !== "" ? (
              <div
                onClick={() => {
                  if (!address) {
                    setOpenModal(true);
                  } else {
                    setOnPopupGem(true);
                    setITEM("SPINEL");
                  }
                }}
              >
                <img src={item1} className={classes.gem} />
              </div>
            ) : (
              <div
                className={classes.titleImg}
                onClick={() => {
                  if (!address) {
                    setOpenModal(true);
                  } else {
                    setOnPopupGem(true);
                    setITEM("SPINEL");
                  }
                }}
              >
                SPINEL{" "}
              </div>
            )}
          </div>
          <div className={classes.frameBtn}>
            <Img src={frame} className={classes.frameImg} />
            {garnet !== "" ? (
              <div
                onClick={() => {
                  if (!address) {
                    setOpenModal(true);
                  } else {
                    setOnPopupGem(true);
                    setITEM("GARNET");
                  }
                }}
              >
                <img src={item2} className={classes.gem2} />
              </div>
            ) : (
              <div
                onClick={() => {
                  if (!address) {
                    setOpenModal(true);
                  } else {
                    setOnPopupGem(true);
                    setITEM("GARNET");
                  }
                }}
                className={classes.titleImg}
              >
                GARNET{" "}
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
      {openModal ? (
        <ModalConnect type="another" open={openModal} setOpen={setOpenModal} />
      ) : null}
      {onPopupCard ? (
        <PopupCard
          selectCard={onClickSelectCard}
          setOpen={setOnPopupCard}
          type="UPGRADE"
          open={onPopupCard}
        />
      ) : null}
      {onPopupGem && (
        <PopupGem
          setOpen={setOnPopupGem}
          selectGem={ITEM === "SPINEL" ? onSelectSpinel : onSelectGarnet}
          open={onPopupGem}
          type="UPGRADE"
          item={ITEM}
        />
      )}
      {open && (
        <PopupUpgrade
          setClose={setOpen}
          cardOld={cardInfo}
          cardNew={getUpgrade}
          reset={() => {
            setSpinel("");
            setGarnet("");
            setCardInfo("");
            setGetUpgrade("");
          }}
        />
      )}
      {loading && <LoadingPage />}
      {error && <ModalError onClick={setError} errors={status} />}
      {!allowance ? <ModalApprove /> : null}
      {noti ? <ModalDynamic title={status} onClick={setNoti} /> : null}
    </div>
  );
};
