import React, { useState } from "react";
import "../../styles/BorderEffect.css"
import { useStyles } from "./style"
import clsx from "clsx";
import "./styleButton.css"
import greyBtn from "../../assets/button/greyBtn.png";
import woodBtn from "../../assets/theme.png";

export const AuthButton = ({title, onClick,effect}) => {
    return(
        <>
        <div className={effect} onClick={onClick}>
            <span></span>
            <span></span>
            <span></span>
            {title}
        </div>
        </>
    )
}

export const SimpleButton = ({title, color, effect, backGround, onClick, className}) => {
    const classes = useStyles()
    return(
        <button onClick={onClick} className={clsx('button font-face-gm',effect, className)} style={{color:color, backgroundColor:backGround}}>
            {title}
        </button>
    )
}
export const ButtonImage = ({title,image,onClick,color}) => {
    const classes = useStyles()
    return(
        <div classes={classes.contain} onClick={onClick}>
            <img src={image} className={classes.img}/>
            <div className={classes.buttonImg} style={{color:color}}>{title}</div>
        </div>
    )
}

export const GreyButton = ({onClick, title}) => {
    return (
        <button className="greyButton" onClick={onClick}>
            <img className="greyButton-img" src={greyBtn} />
            <div className="greyButton-content">{title}</div>
        </button>
    )
}

export const WoodButton = ({onClick, title}) => {
    return (
        <button className="woodButton" onClick={onClick}>
            <img className="woodButton-img" src={woodBtn} />
            <div className="woodButton-content">{title}</div>
        </button>
    )
}

