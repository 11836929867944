import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  containModal:{
    position: 'fixed', /* Stay in place */
    zIndex: 88, /* Sit on top */
    left: 0,
    top: 0,
    width: '100%', /* Full width */
    height: '100%', /* Full height */
    overflow: 'auto', /* Enable scroll if needed */
    backgroundColor: 'rgb(0,0,0)', /* Fallback color */
    backgroundColor: 'rgba(0,0,0,0.4)', /* Black w/ opacity */
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  
}))