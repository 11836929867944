import { makeStyles } from "@material-ui/core/styles";
import frameCard from "../../assets/03-01.png";
export const useStyles = makeStyles((theme) => ({
  cover: {
    minHeight: "100vh",
  },
  imgCardBuyPage: {
    width: "100%",
    height: "auto",
    maxWidth:250,
    borderRadius: 10,
  },
  imgCardDefault: {
    maxWidth:180,
    width: "100%",
    height: "auto",
    marginTop:72
  },
  imgCardDefault2: {
    maxWidth:250,
    width: "100%",
    height: "auto",
    marginTop:72
  },
  btnBuy: {
    height: "auto",
    maxWidth: "200px",
  },
  containCard: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  containCard2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 250,
    },
    marginLeft:-60
  },
  containCard1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  containBtn: {
    display: "flex",
    marginTop: "16px",
    marginLeft: "20px",
    marginRight: "20px",
    flexDirection: "column",
    justifyContent: "center",
  },
  btnGame: {
    background:
      "linear-gradient(to top,#6a1111,#711414,#801a1a,#a72929,#cd3838,#fa4a4a)",
    padding: "10px 20px",
    fontSize: "2.5vmin",
    color: "white",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      transition: "all 0.2s ease-in-out",
      textShadow: "2px 2px 8px #fff",
    },
    border: "none",
    borderRadius: 6,
    marginBottom: 16,
    marginTop: 16,
  },
  btnGame2: {
    background:
      "linear-gradient(to top,#6a1111,#711414,#801a1a,#a72929,#cd3838,#fa4a4a)",
    padding: "10px 20px",
    fontSize: "2.5vmin",
    color: "white",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      transition: "all 0.2s ease-in-out",
      textShadow: "2px 2px 8px #fff",
    },
    border: "none",
    borderRadius: 6,
    marginBottom: 16,
    marginTop: 16,
  },
  // main:{
  //   position:"relative",
  //   display:"flex",
  //   justifyContent:"center",
  // },
  // inMain:{
  //   position:'apsolute',
  //   left: '50%',
  //   top: '50%',
  //   transform: 'translate(-50%,-50%)'

  // },
  containBtnGame: {
    display: "flex",
    justifyContent: "center",
    gap: "0 9px",
  },
  frameCard: {
    width: "100%",
    maxWidth: 400,
    height: "auto",
    position: "absolute",
  },
  frame: {
    width: 300,
    height: 570,
    backgroundImage: `url(${frameCard})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // margin: 64,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      height: 470,
    },
    marginLeft:60
  },
  relative:{
    position: "relative",
    height: 405,
  },
  cardPrint:{
    position: "absolute",
    width: "30px",
    top: 14,
    left: 14,
  },
  class:{
    position: "absolute",
    width: "40px",
    height: "40px",
    marginLeft: "-145px",
    marginTop: "235px",
    zIndex: "1",
  },
  counter:{
    width: "14px",
    height: "14px",
  },
  layout1:{
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  firework:{position:'absolute',left:'50%',right:"50%"}
}));
